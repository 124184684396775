import { Dispatch } from "redux";
import { IDType } from "../../config/types";
import { defaultGet, fetchError } from "./all";
import { MatchLocationEnum, SportEnum } from "../../client/api";
import { MatchActions, MatchActionTypes } from "./types";
import { numberToDay } from "../../config/utils";
import client from "../../client";

export function fetchMatch(dispatch: Dispatch<MatchActions>, id: IDType) {
  defaultGet(
    dispatch,
    MatchActionTypes.FETCH_SUCCESS_MATCHES,
    MatchActionTypes.FETCH_ERROR,
    MatchActionTypes.FETCH_MATCH,
    client().getMatch,
    false,
    false,
    id
  );
}

export function fetchMatchDays(
  dispatch: Dispatch<MatchActions>,
  aoi: IDType,
  date: Date,
  type: "today" | "bigger" | "smaller" | "month",
  sports: SportEnum
) {
  defaultGet(
    dispatch,
    MatchActionTypes.FETCH_SUCCESS_MATCH_DAYS,
    MatchActionTypes.FETCH_ERROR,
    MatchActionTypes.FETCH_MATCH_DAYS,
    client().getMatchDays,
    false,
    false,
    date,
    { type, aoi },
    sports
  );
}

export function fetchMatchDaysOfLeague(
  dispatch: Dispatch<MatchActions>,
  league: IDType,
  date: Date,
  type: "today" | "bigger" | "smaller" | "month",
  sports: SportEnum
) {
  client()
    .getMatchDays(date, { type, league }, sports)
    .then(
      (response) => {
        dispatch({
          type: MatchActionTypes.FETCH_SUCCESS_MATCH_DAYS_LEAGUE,
          payload: {
            id: league,
            response: response.map((d) => new Date(d)),
            fetchDate: new Date(),
          },
        });
      },
      (e) => fetchError(e, MatchActionTypes.FETCH_ERROR, dispatch)
    );
  dispatch({ type: MatchActionTypes.FETCH_MATCH_DAYS_LEAGUE });
}

export function fetchLeagueMatchesOfDay(
  dispatch: Dispatch<MatchActions>,
  aoi: IDType,
  date: number,
  sports: SportEnum
) {
  defaultGet(
    dispatch,
    MatchActionTypes.FETCH_SUCCESS_LEAGUE_MATCHES,
    MatchActionTypes.FETCH_ERROR,
    MatchActionTypes.FETCH_LEAGUE_MATCHES_DAY,
    client().getLeagueMatchesOfDay,
    false,
    { id: aoi, date },
    numberToDay(date),
    aoi,
    sports
  );
}

export function fetchLeagueMatchesOfDayLeague(
  dispatch: Dispatch<MatchActions>,
  date: number,
  leagueID: IDType
) {
  defaultGet(
    dispatch,
    MatchActionTypes.FETCH_SUCCESS_MATCHES_OF_LEAGUE,
    MatchActionTypes.FETCH_ERROR,
    MatchActionTypes.FETCH_MATCHES_OF_LEAGUE,
    client().getLeagueMatchesOfDayLeague,
    false,
    { id: leagueID, date },
    numberToDay(date),
    leagueID
  );
}

export function getLineupOfMatch(
  dispatch: Dispatch<MatchActions>,
  matchID: IDType,
  team: MatchLocationEnum
) {
  defaultGet(
    dispatch,
    MatchActionTypes.FETCH_LINEUP_SUCCESS,
    MatchActionTypes.FETCH_ERROR,
    MatchActionTypes.FETCH_LINEUP,
    client().getLineup,
    false,
    { id: matchID, date: team === "home" ? 1 : 2 },
    matchID,
    team
  );
}
