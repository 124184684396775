import { EnhancedStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { Favorite, IDType, LanguageISO } from "../config/types";
import {
  AdministrationService,
  AdPositionEnum,
  AdService,
  ApiError,
  AreaOfInterest,
  AreaOfInterestService,
  AuthenticationService,
  AuthResponse,
  BadgeCreate,
  BadgePatch,
  BadgeService,
  BadgeTypeEnum,
  BlogCreate,
  BlogService,
  CancelablePromise,
  EventService,
  ExportService,
  GoalEventCreate,
  GoalEventPatch,
  GoalTypeEnum,
  HomeMatch,
  Language,
  LanguageLocaleEnum,
  LanguageService,
  League,
  LeagueCategoryService,
  LeagueService,
  LineupService,
  LocationCreate,
  LocationService,
  MatchDayService,
  MatchLeague,
  MatchLeaguePatch,
  MatchLocationEnum,
  MatchService,
  MatchSmall,
  MatchTournamentGroup,
  MatchTournamentGroupCreate,
  MatchTournamentGroupPatch,
  MatchTournamentStage,
  MatchTournamentStageCreate,
  MatchTournamentStagePatch,
  MediaCrop,
  OpenAPI,
  PlayerCreate,
  PlayerPatch,
  PlayerPositionEnum,
  PlayerService,
  SearchRequest,
  SearchResult,
  SearchService,
  SearchTypeEnum,
  SponsorCreate,
  SponsorPatch,
  SponsorService,
  SportEnum,
  SquadList,
  SquadService,
  StandingService,
  StatisticKeyEnum,
  StatisticsService,
  Team,
  TeamListElement,
  TeamPatch,
  TeamService,
  Tournament,
  TournamentCreate,
  TournamentGroup,
  TournamentGroupCreate,
  TournamentGroupPatch,
  TournamentPatch,
  TournamentSecretPatch,
  TournamentService,
  TournamentStage,
  TournamentStageCreate,
  TournamentStagePatch,
  TournamentTeam,
  TournamentTeamCreate,
  TransferService,
  User,
  UserBadgeCreateDelete,
  UserCreate,
  UserMediaService,
  UserService,
} from "./api";
import {
  errorLogging,
  formatDate,
  isLocalDev,
  MINUTE_IN_MS,
} from "../config/utils";
import {
  AGB_LEVEL,
  AMOUNT_MATCHDAYS,
  PROD_ENDPOINT,
} from "../config/constants";
import { addLog } from "../store/middleware/logger";

class Client {
  public static token: string | undefined = undefined;

  private static JWT_STORAGE = "jwt_token";

  private static JWT_REFRESH_STORAGE = "jwt_refresh_token";

  private static refreshToken: string | undefined = undefined;

  private static __instance: Client | null = null;

  private endpoint = `https://${PROD_ENDPOINT}`;

  private constructor(endpoint: string) {
    this.endpoint = `https://${endpoint}`;
    OpenAPI.TOKEN = Client.getToken;
    OpenAPI.BASE = this.endpoint;
  }

  static getInstance(endpoint?: string): Client {
    if (Client.__instance === null) {
      if (!endpoint) endpoint = PROD_ENDPOINT;
      errorLogging(`first client call with ${endpoint}`);
      Client.__instance = new Client(endpoint as string);
    }
    return Client.__instance;
  }

  public static getToken(): Promise<string> {
    return new Promise<string>((resolve) => {
      if (Client.token) resolve(Client.token);
      const val = window.localStorage.getItem(Client.JWT_STORAGE);
      if (val) {
        Client.token = val;
        resolve(val);
      }
      resolve("");
    });
  }

  public static getRefreshToken(): Promise<string> {
    return new Promise<string>((resolve) => {
      if (Client.refreshToken) resolve(Client.refreshToken);
      const val = window.localStorage.getItem(Client.JWT_REFRESH_STORAGE);
      if (val) {
        Client.refreshToken = val;
        resolve(val);
      }
      resolve("");
    });
  }

  public static setToken(
    response:
      | AuthResponse
      | {
          refresh_token: string;
          access_token: string;
        }
  ) {
    Client.token = response.access_token;
    Client.refreshToken = response.refresh_token;
    window.localStorage.setItem(Client.JWT_STORAGE, response.access_token);
    window.localStorage.setItem(
      Client.JWT_REFRESH_STORAGE,
      response.refresh_token
    );
  }

  public static async removeToken() {
    Client.token = undefined;
    Client.refreshToken = undefined;
    window.localStorage.removeItem(Client.JWT_STORAGE);
    window.localStorage.removeItem(Client.JWT_REFRESH_STORAGE);
  }

  setEndpoint(endpoint: string) {
    this.endpoint = `https://${endpoint}`;
    OpenAPI.BASE = this.endpoint;
  }

  getEndpoint(): string {
    return this.endpoint.replace("https://", "");
  }

  public setLang(lang: LanguageISO) {
    OpenAPI.HEADERS = { "User-Lang": lang };
  }

  public changeLanguage(languageId: IDType) {
    return UserService.patchUserUserPatch({ languageId });
  }

  public telAvailable(tel: string) {
    return UserService.telAvailableUserTelAvailableTelGet(tel);
  }

  addFavorite(favorite: Favorite) {
    switch (favorite.type) {
      case "match":
        return UserService.favoriteMatchUserFavoriteMatchMatchIdPut(
          favorite.id
        );
      case "team":
        return UserService.favoriteTeamUserFavoriteTeamTeamIdPut(favorite.id);
      case "league":
        return UserService.favoriteLeagueUserFavoriteLeagueLeagueIdPut(
          favorite.id
        );

      default:
        return new CancelablePromise<User>((_, reject) => {
          reject("no_type_specified");
        });
    }
  }

  removeFavorite(favorite: Favorite) {
    switch (favorite.type) {
      case "match":
        return UserService.favoriteMatchUserFavoriteMatchMatchIdPut(
          favorite.id
        );
      case "team":
        return UserService.unfavoriteTeamUserUnfavoriteTeamTeamIdPut(
          favorite.id
        );
      case "league":
        return UserService.unfavoriteLeagueUserUnfavoriteLeagueLeagueIdPut(
          favorite.id
        );
      default:
        return new CancelablePromise<User>((_, reject) => {
          reject("no_type_specified");
        });
    }
  }

  public registerPatch(
    username: string,
    givenName: string,
    familyName: string,
    email: string,
    password: string,
    tel: string,
    newsletter: boolean
  ) {
    return new CancelablePromise((resolve, reject) => {
      UserService.registerUserUserRegisterPost({
        familyName,
        email,
        password,
        givenName,
        tel,
        agbLevel: AGB_LEVEL,
        newsletter,
      }).then((response) => {
        Client.setToken(response);
        UserService.patchUserUserPatch({ username }).then(
          (response2) => {
            resolve(response2);
          },
          () => {
            resolve(response.user);
          }
        );
      }, reject);
    });
  }

  public logout() {
    return Client.removeToken();
  }

  public deleteAccount() {
    return new CancelablePromise((resolve, reject) => {
      UserService.deleteUserUserDelete().then((response) => {
        Client.removeToken().then(() => {
          resolve(response);
        });
      }, reject);
    });
  }

  me() {
    return new CancelablePromise<User>((resolve, reject) => {
      UserService.getUserMeUserMeGet().then((user) => {
        if (user) {
          resolve(user);
        } else {
          // This is a very rare error and can only happen in DEV I think
          const error: ApiError = {
            body: "Unauthorized",
            name: "ApiError",
            statusText: "Unauthorized",
            url: "/user/me",
            message: "Unauthorized",
            status: 401,
            request: { method: "GET", url: "/user/me" },
          };
          reject(error);
        }
      }, reject);
    });
  }

  public loggedIn() {
    return typeof OpenAPI.TOKEN !== "undefined";
  }

  public authorize(email: string, password: string): CancelablePromise<User> {
    return new CancelablePromise((resolve, reject) => {
      AuthenticationService.secureLoginWithEmailPasswordAuthSecureLoginPost({
        email,
        password,
      }).then((response) => {
        Client.setToken(response);
        resolve(response.user);
      }, reject);
    });
  }

  pwReset(emailOrTel: string, password: string, hash: string) {
    return new CancelablePromise((resolve, reject) => {
      AuthenticationService.confirmPasswordForgotAuthPasswordForgotConfirmEmailOrTelPost(
        emailOrTel,
        { password, code: hash }
      ).then((response) => {
        Client.setToken(response);
        resolve(response.user);
      }, reject);
    });
  }

  refreshToken(): Promise<User> {
    return new Promise((resolve, reject) => {
      OpenAPI.TOKEN = Client.getRefreshToken;
      AuthenticationService.refreshAuthRefreshPost().then(
        (response) => {
          Client.setToken(response);
          OpenAPI.TOKEN = Client.getToken;
          resolve(response.user);
        },
        (error) => {
          OpenAPI.TOKEN = Client.getToken;
          reject(error);
        }
      );
    });
  }

  register(body: UserCreate): CancelablePromise<User> {
    return new CancelablePromise<User>((resolve, reject) => {
      Client.removeToken().then(() => {
        UserService.createUserUserPost(body).then((response) => {
          Client.setToken(response);
          resolve(response.user);
        }, reject);
      });
    });
  }

  confirmMail(code: string) {
    return new CancelablePromise<User>((resolve, reject) => {
      UserService.confirmMailUserEmailConfirmPost(code).then((auth) => {
        Client.setToken(auth);
        resolve(auth.user);
      }, reject);
    });
  }

  emailAvailable(email: string) {
    return UserService.emailAvailableUserEmailAvailableEmailGet(email);
  }

  public getSquad(teamId: IDType) {
    return SquadService.getSquadSquadTeamIdGet(teamId);
  }

  public getPLayerPositions() {
    return PlayerService.getPlayerPositionsPlayerPlayerPositionGet();
  }

  public changeAoi(areaOfInterestId: IDType) {
    return UserService.patchUserUserPatch({ areaOfInterestId });
  }

  getLeaguesOfAoi(id: IDType, sports: SportEnum): CancelablePromise<League[]> {
    return LeagueService.getLeaguesLeagueGet(id, sports);
  }

  getLeague(id: IDType): CancelablePromise<League> {
    return LeagueService.getLeagueByIdLeagueLeagueIdGet(id);
  }

  getLeagueCategories() {
    return LeagueCategoryService.getLeagueCategoriesLeagueCategoryGet();
  }

  getTeamsOfLeague(id: IDType): CancelablePromise<TeamListElement[]> {
    return TeamService.getTeamsTeamGet(id);
  }

  getTeam(id: IDType): CancelablePromise<Team> {
    return TeamService.getTeamByIdTeamTeamIdGet(id);
  }

  patchTeam(id: IDType, body: TeamPatch) {
    return TeamService.patchTeamTeamTeamIdPatch(id, body);
  }

  addSponsor(teamId: IDType, body: SponsorCreate) {
    return SponsorService.createSponsorSponsorTeamTeamIdPost(teamId, body);
  }

  patchSponsor(teamId: IDType, body: SponsorPatch) {
    return SponsorService.patchSponsorSponsorSponsorIdPut(teamId, body);
  }

  removeSponsor(sponsorId: IDType) {
    return SponsorService.deleteSponsorSponsorSponsorIdDelete(sponsorId);
  }

  getLanguages(): CancelablePromise<Language[]> {
    return LanguageService.getLanguagesLanguageGet();
  }

  getLanguage(id: IDType): CancelablePromise<Language> {
    return LanguageService.getLanguageLanguageLanguageIdGet(id);
  }

  getAois(): CancelablePromise<AreaOfInterest[]> {
    return AreaOfInterestService.getAreaOfInterestsAreaOfInterestGet();
  }

  getAoi(id: IDType, store: EnhancedStore) {
    return new CancelablePromise((resolve, reject, onCancel) => {
      if (store.getState().aois.value.length) {
        this.filterAoi(store.getState().aois.value, id, resolve, reject);
      } else {
        AreaOfInterestService.getAreaOfInterestsAreaOfInterestGet().then(
          (aois) => {
            if (!onCancel.isCancelled) {
              this.filterAoi(aois, id, resolve, reject);
            }
          },
          reject
        );
      }
    });
  }

  getMatchDays(
    date: Date,
    options:
      | { aoi: IDType; type: "today" | "bigger" | "smaller" | "month" }
      | { league: IDType; type: "month" | "today" | "bigger" | "smaller" },
    sports: SportEnum
  ): CancelablePromise<string[]> {
    const dateString = formatDate(
      date,
      LanguageLocaleEnum.DE,
      true,
      false,
      true
    );
    switch (options.type) {
      case "month":
        return MatchDayService.getMatchDatesInMonthMatchDayMonthGet(
          dateString,
          "aoi" in options ? options.aoi : undefined,
          "aoi" in options ? undefined : options.league,
          undefined,
          sports
        );
      case "today":
        return MatchDayService.getMatchDatesTodayMatchDayTodayAmountDaysGet(
          AMOUNT_MATCHDAYS,
          "aoi" in options ? options.aoi : undefined,
          "aoi" in options ? undefined : options.league,
          undefined,
          sports
        );
      case "bigger":
        return MatchDayService.getMatchDatesBiggerMatchDayBiggerAmountDaysGet(
          AMOUNT_MATCHDAYS,
          dateString,
          "aoi" in options ? options.aoi : undefined,
          "aoi" in options ? undefined : options.league,
          undefined,
          sports
        );
      case "smaller":
        return MatchDayService.getMatchDatesSmallerMatchDaySmallerAmountDaysGet(
          AMOUNT_MATCHDAYS,
          dateString,
          "aoi" in options ? options.aoi : undefined,
          "aoi" in options ? undefined : options.league,
          undefined,
          sports
        );
      default:
        return new CancelablePromise<string[]>((resolve) => {
          Sentry.captureException(new Error("Invalid Matchday Type"));
          resolve([]);
        });
    }
  }

  getLeagueMatchesOfDay(
    date: Date,
    aoi: IDType,
    sports: SportEnum
  ): CancelablePromise<HomeMatch[]> {
    return MatchService.getHomeMatchesMatchesV2HomeGet(
      aoi,
      formatDate(date, LanguageLocaleEnum.DE, true, false, true),
      sports
    );
  }

  getLeagueMatchesOfDayLeague(
    date: Date,
    leagueID: IDType
  ): CancelablePromise<MatchSmall[]> {
    return MatchService.getMatchesByLeagueAndDateMatchesV2LeagueLeagueIdDateMatchDateGet(
      leagueID,
      formatDate(date, LanguageLocaleEnum.DE, true, false, true)
    );
  }

  getTable(leagueID: IDType) {
    return StandingService.getStandingByLeagueStandingLeagueAllLeagueIdGet(
      leagueID
    );
  }

  public loginWithSMS(identifier: { email: string } | { tel: string }) {
    if ("tel" in identifier) {
      return AuthenticationService.loginWithTelAndSmsAuthLoginWithTelAndSmsTelPost(
        identifier.tel
      );
    }
    return AuthenticationService.loginWithMailAndSmsAuthLoginWithMailAndSmsMailPost(
      identifier.email
    );
  }

  getMatch(id: IDType) {
    return MatchService.getMatchLeagueMatchesV2LeagueMatchIdGet(id);
  }

  getPlayersOfTeam(id: IDType) {
    return PlayerService.getPlayersByTeamPlayerTeamTeamIdGet(id);
  }

  getPlayer(id: IDType) {
    return PlayerService.getPlayerPlayerPlayerIdGet(id);
  }

  patchPlayer(id: IDType, playerPatch: PlayerPatch) {
    return PlayerService.patchPlayerPlayerPlayerIdPatch(id, playerPatch);
  }

  addPlayer(playerPost: PlayerCreate) {
    return PlayerService.createPlayerPlayerPost(playerPost);
  }

  removePlayer(id: IDType) {
    return PlayerService.deletePlayerPlayerPlayerIdDelete(id);
  }

  getTeamStatistics(id: IDType) {
    return StatisticsService.getSquadStatisticStatisticsSquadTeamTeamIdGet(id);
  }

  getTeamStatisticsDetail(id: IDType, stat: StatisticKeyEnum) {
    return StatisticsService.getSquadStatisticsDetailStatisticsSquadTeamTeamIdDetailStatisticKeyGet(
      id,
      stat
    );
  }

  search(q: string, length = 5, offset = 0) {
    return SearchService.searchSearchQueryGet(q, length, offset);
  }

  searchExt(
    query: string,
    length: number,
    offset: number,
    type: "team" | "league" | "player"
  ): CancelablePromise<SearchResult> {
    switch (type) {
      case "player":
        return new CancelablePromise<SearchResult>((resolve, reject) => {
          SearchService.searchPlayerSearchPlayerQueryGet(
            query,
            length,
            offset
          ).then((players) => {
            resolve({ query, players, leagues: [], teams: [] });
          }, reject);
        });
      case "league":
        return new CancelablePromise<SearchResult>((resolve, reject) => {
          SearchService.searchLeagueSearchLeagueQueryGet(
            query,
            length,
            offset
          ).then((leagues) => {
            resolve({ query, players: [], leagues, teams: [] });
          }, reject);
        });
      case "team":
        return new CancelablePromise<SearchResult>((resolve, reject) => {
          SearchService.searchTeamSearchTeamQueryGet(
            query,
            length,
            offset
          ).then((teams) => {
            resolve({ query, players: [], leagues: [], teams });
          }, reject);
        });
      default:
        return SearchService.searchSearchQueryGet(query, length, offset);
    }
  }

  searchTeamAutocomplete(q: string) {
    return SearchService.searchTeamSearchTeamQueryGet(q, 10, 0);
  }

  postGoalEvent(
    matchID: IDType,
    minute: number,
    teamId: IDType,
    goal1: number,
    goal2: number,
    playerId?: IDType,
    assistId?: IDType,
    goalTypeKey?: GoalTypeEnum,
    ignoreChecks?: boolean
  ) {
    return EventService.addGoalEventEventGoalMatchIdPost(
      matchID,
      {
        minute,
        teamId,
        playerId,
        assistId,
        goal1,
        goal2,
        goalTypeKey,
      },
      ignoreChecks
    );
  }

  deleteEvent(id: IDType) {
    return EventService.deleteEventEventEventIdDelete(id);
  }

  getLineup(matchId: IDType, team: MatchLocationEnum) {
    return LineupService.getLineupByMatchAndTeamLineupMatchMatchIdTeamGet(
      matchId,
      team
    );
  }

  createLocation(teamId: IDType, oldLocations: IDType[], body: LocationCreate) {
    return new CancelablePromise<Team>((resolve, reject) => {
      LocationService.createLocationLocationPost(body).then((location) => {
        TeamService.patchTeamTeamTeamIdPatch(teamId, {
          locations: oldLocations.concat([location.id]),
        }).then(resolve, reject);
      });
    });
  }

  createNews(news: BlogCreate) {
    return BlogService.createBlogBlogPost(news);
  }

  fetchTransfers(aoiId: IDType, offset: number, limit: number) {
    return TransferService.getTransferTransferAreaOfInterestAreaOfInterestIdGet(
      aoiId,
      undefined,
      undefined,
      offset,
      limit
    );
  }

  getAds(pos: AdPositionEnum) {
    return AdService.getAdsByPositionAdPositionPositionGet(pos);
  }

  imageLink(aspectRatio: number, cropData: MediaCrop) {
    return UserMediaService.requestImageUploadUserMediaImagePost(
      aspectRatio,
      cropData
    );
  }

  maxVideoLength() {
    return UserMediaService.getMaxUploadLengthSecondsUserMediaMaxUploadLengthSecondsGet();
  }

  tusVideoLink(
    aspectRatio: number,
    uploadLength: number,
    name: string,
    cropData: MediaCrop
  ) {
    return UserMediaService.requestTusUploadUserMediaTusPost(
      aspectRatio,
      uploadLength,
      cropData,
      name
    );
  }

  deleteVideoCloudflare(id: string) {
    return UserMediaService.deleteVideoUserMediaVideoVideoIdDelete(id);
  }

  deleteImageCloudflare(id: string) {
    return UserMediaService.deleteImageUserMediaImageImageIdDelete(id);
  }

  clearCache() {
    return AdministrationService.clearCacheAdminClearCachePost();
  }

  getBadges() {
    return BadgeService.getBadgesBadgeGet();
  }

  patchBadge(id: BadgeTypeEnum, badge: BadgePatch) {
    return BadgeService.patchBadgesBadgeBadgeTypePatch(id, badge);
  }

  createBadge(badge: BadgeCreate) {
    return BadgeService.createBadgesBadgePost(badge);
  }

  giveBadge(badge: UserBadgeCreateDelete) {
    return BadgeService.giveUserBadgeBadgeGivePut(badge);
  }

  generateReport(aoiId: number, matchday?: number) {
    return ExportService.exportPlayerScoreExportPlayerScoreAreaOfInterestIdPost(
      aoiId,
      matchday
    );
  }

  removeBadge(badge: UserBadgeCreateDelete) {
    return BadgeService.removeUserBadgeBadgeRemoveDelete(badge);
  }

  bulkSearch(searchType: SearchTypeEnum, searchBody: SearchRequest[]) {
    return SearchService.bulkSearchSearchBulkPost(searchType, searchBody);
  }

  addTeamToLeague(leagueId: IDType, teamIds: IDType[]) {
    return new CancelablePromise<League>(async (resolve, reject) => {
      if (teamIds.length !== 0) {
        const promises: Promise<League>[] = [];
        teamIds.forEach((teamId) => {
          promises.push(
            LeagueService.addTeamToLeagueLeagueAddTeamPost(leagueId, teamId)
          );
        });
        Promise.allSettled(promises).then((results) => {
          let league: League | undefined;
          results.forEach((res) => {
            if (res.status === "fulfilled") league = res.value;
          });
          if (league) resolve(league);
          else reject("No team added");
        });
      }
    });
  }

  removeTeamFromLeague(leagueId: IDType, teamIds: IDType[]) {
    return new CancelablePromise<League>(async (resolve, reject) => {
      if (teamIds.length !== 0) {
        const promises: Promise<League>[] = [];
        teamIds.forEach((teamId) => {
          promises.push(
            LeagueService.removeTeamFromLeagueLeagueRemoveTeamPost(
              leagueId,
              teamId
            )
          );
        });
        Promise.allSettled(promises).then((results) => {
          let league: League | undefined;
          results.forEach((res) => {
            if (res.status === "fulfilled") league = res.value;
          });
          if (league) resolve(league);
          else reject("No team removed");
        });
      }
    });
  }

  bulkDeleteMatches(ids: IDType[]) {
    return new CancelablePromise<IDType[]>((resolve, reject) => {
      const promises: Promise<number>[] = [];
      ids.forEach((id) => {
        promises.push(MatchService.deleteMatchMatchesV2MatchIdDelete(id));
      });
      Promise.allSettled(promises).then((results) => {
        const retval: IDType[] = [];
        results.forEach((result) => {
          if (result.status === "fulfilled") {
            retval.push(result.value);
          }
        });
        resolve(retval);
      }, reject);
    });
  }

  bulkPatchMatches(patches: MatchLeaguePatch[]) {
    return new CancelablePromise<MatchLeague[]>((resolve, reject) => {
      const promises: Promise<MatchLeague>[] = [];
      patches.forEach((patch) => {
        const id = patch.locationId;
        patch.locationId = undefined;
        if (!id) return;
        promises.push(
          MatchService.patchLeagueMatchMatchesV2LeagueMatchIdPatch(id, patch)
        );
      });
      Promise.allSettled(promises).then((results) => {
        const retval: MatchLeague[] = [];
        results.forEach((result) => {
          if (result.status === "fulfilled") {
            retval.push(result.value);
          }
        });
        resolve(retval);
      }, reject);
    });
  }

  private filterAoi(
    aois: AreaOfInterest[],
    id: IDType,
    resolve: (value: PromiseLike<unknown> | unknown) => void,
    reject: (reason?: any) => void
  ): void {
    const aoisFilter = aois.filter((aoi) => aoi.id === id);
    if (aoisFilter.length === 1) {
      resolve(aoisFilter[0]);
    } else {
      reject(
        new ApiError(
          { url: "aoi", method: "GET" },
          {
            body: "asd",
            ok: false,
            status: 420,
            statusText: "invalid_id",
            url: "locale",
          },
          "invalid_id"
        )
      );
    }
  }

  getCupsOfDay(aoi: IDType, date: Date, sports: SportEnum) {
    return TournamentService.getTournamentByDateTournamentAreaOfInterestAreaOfInterestIdDateDateGet(
      aoi,
      formatDate(date, LanguageLocaleEnum.DE, true, false, true),
      sports
    );
  }

  getCup(id: IDType) {
    return TournamentService.getTournamentTournamentTournamentIdGet(id);
  }

  getCupStages(id: IDType) {
    return TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
      id
    );
  }

  getCupStandings(id: IDType) {
    return TournamentService.getTournamentGroupStandingsTournamentGroupStandingsTournamentIdGet(
      id
    );
  }

  getCupGroups(id: IDType) {
    return TournamentService.getTournamentGroupsTournamentGroupTournamentIdGet(
      id
    );
  }

  fetchCupTeam(teamId: IDType, cupId: IDType) {
    return TeamService.getTournamentTeamTeamTeamIdTournamentTournamentIdGet(
      teamId,
      cupId
    );
  }

  getCupMatches(cupId: IDType) {
    return MatchService.getMatchesByTournamentMatchesV2TournamentTournamentIdGet(
      cupId
    );
  }

  getCupMatch(matchId: IDType, isGroup: boolean) {
    if (isGroup) {
      return MatchService.getMatchTournamentGroupMatchesV2TournamentGroupMatchIdGet(
        matchId
      );
    }
    return MatchService.getMatchTournamentStageMatchesV2TournamentStageMatchIdGet(
      matchId
    );
  }

  getCupTopScorer(cupId: IDType) {
    return TournamentService.getTournamentStatisticsTournamentStatisticsTournamentIdGet(
      cupId,
      StatisticKeyEnum.PLAYER_MOST_GOALS
    );
  }

  private createStageMatch(
    startTime: number,
    matchLengthMs: number,
    a: string,
    b: string,
    stageId: IDType,
    nextMatch: IDType | null
  ) {
    return MatchService.createMatchTournamentStageMatchesV2StageTournamentStageIdPost(
      stageId,
      {
        team1Placeholder: a,
        team2Placeholder: b,
        originalStartTime: formatDate(
          new Date(startTime),
          LanguageLocaleEnum.DE,
          true
        ),
        endTime: formatDate(
          new Date(startTime + matchLengthMs),
          LanguageLocaleEnum.DE,
          true
        ),
        team1Id: null,
        team2Id: null,
        locationId: null,
        nextMatchId: nextMatch,
      }
    );
  }

  patchCupPdf(cupId: IDType, doc: File) {
    const data = new FormData();
    const link = `${Client.getInstance().endpoint}/tournament/pdf/${cupId}`;
    data.append("pdf", doc);
    const msg: Record<string, any> = { Post: link, Body: data };
    if (isLocalDev()) {
      console.log("\x1b[34m%s\x1b[0m", `POST: ${link}`);
    }

    addLog("req", msg);
    return fetch(link, {
      method: "PATCH",
      body: data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${Client.token}`,
      },
    });
  }

  patchCup(cupId: IDType, cup: TournamentPatch, location?: string, doc?: File) {
    return new CancelablePromise<Tournament>((resolve, reject) => {
      if (location) {
        if (doc) {
          client()
            .patchCupPdf(cupId, doc)
            .then(() => {
              LocationService.createLocationLocationPost({
                name: { textDE: location, textEN: location, textIT: location },
                lat: 0,
                long: 0,
              }).then((loc) => {
                TournamentService.patchTournamentTournamentTournamentIdPatch(
                  cupId,
                  {
                    ...cup,
                    locationId: loc.id,
                  }
                ).then(resolve, reject);
              });
            }, reject);
        } else {
          LocationService.createLocationLocationPost({
            name: { textDE: location, textEN: location, textIT: location },
            lat: 0,
            long: 0,
          }).then((loc) => {
            TournamentService.patchTournamentTournamentTournamentIdPatch(
              cupId,
              {
                ...cup,
                locationId: loc.id,
              }
            ).then(resolve, reject);
          });
        }
      } else if (doc) {
        client()
          .patchCupPdf(cupId, doc)
          .then(() => {
            TournamentService.patchTournamentTournamentTournamentIdPatch(
              cupId,
              cup
            ).then(resolve, reject);
          }, reject);
      } else {
        TournamentService.patchTournamentTournamentTournamentIdPatch(
          cupId,
          cup
        ).then(resolve, reject);
      }
    });
  }

  patchCupTimes(cupId: IDType, cup: TournamentPatch, groupIds: IDType[]) {
    return new CancelablePromise<Tournament>((resolve, reject) => {
      const promises: CancelablePromise<TournamentGroup>[] = [];
      groupIds.forEach((id) => {
        promises.push(
          TournamentService.patchGroupTournamentGroupTournamentGroupIdPatch(
            id,
            {
              matchSectionAmount: cup.matchSectionAmount,
              matchSectionDurationMinutes: cup.matchSectionDurationMinutes,
              matchSectionPauseDurationMinutes:
                cup.matchSectionPauseDurationMinutes,
            }
          )
        );
      });
      Promise.allSettled(promises).then(() => {
        TournamentService.patchTournamentTournamentTournamentIdPatch(
          cupId,
          cup
        ).then(resolve, reject);
      });
    });
  }

  patchCupSecrets(cupId: IDType, cup: TournamentSecretPatch) {
    return TournamentService.patchTournamentSecretsTournamentSecretsTournamentIdPatch(
      cupId,
      cup
    );
  }

  addCupPlayer(
    teamId: IDType,
    givenName: string,
    familyName: string,
    jersey: number | undefined
  ) {
    return new CancelablePromise<SquadList>((resolve, reject) => {
      PlayerService.createPlayerPlayerPost({
        mainTeamId: teamId,
        jersey,
        givenName,
        familyName,
        teamPositions: [{ teamId, position: PlayerPositionEnum.MIDFIELD }],
      }).then(() => {
        SquadService.getSquadSquadTeamIdGet(teamId).then(resolve, reject);
      }, reject);
    });
  }

  patchCupPlayer(
    teamId: IDType,
    playerId: IDType,
    givenName: string,
    familyName: string,
    jersey: number | undefined
  ) {
    return new CancelablePromise<SquadList>((resolve, reject) => {
      PlayerService.patchPlayerPlayerPlayerIdPatch(playerId, {
        jersey,
        givenName,
        familyName,
      }).then(() => {
        SquadService.getSquadSquadTeamIdGet(teamId).then(resolve, reject);
      }, reject);
    });
  }

  deleteCupPlayer(teamId: IDType, playerId: IDType) {
    return new CancelablePromise<SquadList>((resolve, reject) => {
      PlayerService.deletePlayerPlayerPlayerIdDelete(playerId).then(() => {
        SquadService.getSquadSquadTeamIdGet(teamId).then(resolve, reject);
      }, reject);
    });
  }

  requestCupManager(cupId: IDType, code: string, teamId: IDType) {
    return UserService.addTournamentManagerToUserUserTournamentManagerTournamentIdPost(
      cupId,
      code,
      teamId
    );
  }

  requestCupAdmin(cupId: IDType, code: string) {
    return UserService.addTournamentAdminToUserUserTournamentAdminTournamentIdPost(
      cupId,
      code
    );
  }

  deleteCupEvent(id: IDType) {
    return EventService.deleteEventEventEventIdDelete(id);
  }

  addCupEvent(matchId: IDType, event: GoalEventCreate, ignoreChecks: boolean) {
    return EventService.addGoalEventEventGoalMatchIdPost(
      matchId,
      event,
      ignoreChecks
    );
  }

  patchCupEvent(id: IDType, event: GoalEventPatch) {
    return EventService.patchGoalEventEventGoalEventIdPatch(id, event);
  }

  startCupMatch(
    id: IDType,
    minutes: number,
    lengthMs: number,
    isGroup: boolean
  ) {
    const now = new Date().getTime();
    const startTime = new Date(now - minutes * MINUTE_IN_MS);
    const endTime = new Date(startTime.getTime() + lengthMs);
    if (isGroup) {
      return MatchService.patchTournamentGroupMatchMatchesV2TournamentGroupMatchIdPatch(
        id,
        {
          startTime: formatDate(startTime, LanguageLocaleEnum.DE, true),
          endTime: formatDate(endTime, LanguageLocaleEnum.DE, true),
        }
      );
    }
    return MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
      id,
      {
        startTime: formatDate(startTime, LanguageLocaleEnum.DE, true),
        endTime: formatDate(endTime, LanguageLocaleEnum.DE, true),
      }
    );
  }

  addOvertimeCupMatch(id: IDType, endTime: Date, isGroup: boolean) {
    if (isGroup) {
      return MatchService.patchTournamentGroupMatchMatchesV2TournamentGroupMatchIdPatch(
        id,
        { endTime: formatDate(endTime, LanguageLocaleEnum.DE, true) }
      );
    }
    return MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
      id,
      { endTime: formatDate(endTime, LanguageLocaleEnum.DE, true) }
    );
  }

  kickOutCupMatch(id: IDType, isGroup: boolean) {
    if (isGroup) {
      return MatchService.patchTournamentGroupMatchMatchesV2TournamentGroupMatchIdPatch(
        id,
        { endTime: formatDate(new Date(), LanguageLocaleEnum.DE, true) }
      );
    }
    return MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
      id,
      { endTime: formatDate(new Date(), LanguageLocaleEnum.DE, true) }
    );
  }

  getTournamentSecrets(id: IDType) {
    return TournamentService.getTournamentSecretsTournamentSecretsTournamentIdGet(
      id
    );
  }

  patchCupMatchStage(
    matchId: IDType,
    matchPatch: MatchTournamentStagePatch,
    location?: string
  ) {
    return new CancelablePromise<MatchTournamentStage>((resolve, reject) => {
      if (location) {
        LocationService.createLocationLocationPost({
          name: { textDE: location, textEN: location, textIT: location },
          lat: 0,
          long: 0,
        }).then((loc) => {
          MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
            matchId,
            {
              ...matchPatch,
              locationId: loc.id,
            }
          ).then(resolve, reject);
        }, reject);
      } else {
        MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
          matchId,
          matchPatch
        ).then(resolve, reject);
      }
    });
  }

  deleteCupMatchGroup(matchId: IDType, cupId: IDType) {
    return new CancelablePromise<TournamentGroup[]>((resolve, reject) => {
      MatchService.deleteMatchMatchesV2MatchIdDelete(matchId).then(() => {
        TournamentService.getTournamentGroupsTournamentGroupTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  deleteCupMatchStage(matchId: IDType, cupId: IDType) {
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      MatchService.deleteMatchMatchesV2MatchIdDelete(matchId).then(() => {
        TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  addCupGroup(cupId: IDType, groupCreate: TournamentGroupCreate) {
    return TournamentService.addGroupToTournamentTournamentGroupTournamentIdPost(
      cupId,
      groupCreate
    );
  }

  deleteCupGroup(groupId: IDType, cupId: IDType) {
    return new CancelablePromise<TournamentGroup[]>((resolve, reject) => {
      TournamentService.removeGroupFromTournamentTournamentGroupTournamentGroupIdDelete(
        groupId
      ).then(() => {
        TournamentService.getTournamentGroupsTournamentGroupTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  patchCupGroup(
    groupId: IDType,
    cupId: IDType,
    groupCreate: TournamentGroupPatch
  ) {
    return new CancelablePromise<TournamentGroup[]>((resolve, reject) => {
      TournamentService.patchGroupTournamentGroupTournamentGroupIdPatch(
        groupId,
        groupCreate
      ).then(() => {
        TournamentService.getTournamentGroupsTournamentGroupTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  addCupStage(cupId: IDType, stageCreate: TournamentStageCreate) {
    return TournamentService.createTournamentStageTournamentStageTournamentIdPost(
      cupId,
      stageCreate
    );
  }

  deleteCupStage(stageId: IDType, cupId: IDType) {
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      TournamentService.removeStageFromTournamentTournamentStageTournamentStageIdDelete(
        stageId
      ).then(() => {
        TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  patchCupStage(
    stageId: IDType,
    cupId: IDType,
    stageCreate: TournamentStagePatch
  ) {
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      TournamentService.patchTournamentStageTournamentStageTournamentStageIdPatch(
        stageId,
        stageCreate
      ).then(() => {
        TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  createCupTeam(cupId: IDType, teamCreate: TournamentTeamCreate) {
    return TournamentService.addTeamToTournamentByNameTournamentTournamentIdTeamNamePost(
      cupId,
      teamCreate
    );
  }

  patchCupTeam(cupId: IDType, teamId: IDType, teamCreate: TeamPatch) {
    return new CancelablePromise<TournamentTeam>((resolve, reject) => {
      TeamService.patchTeamTeamTeamIdPatch(teamId, teamCreate).then(() => {
        TeamService.getTournamentTeamTeamTeamIdTournamentTournamentIdGet(
          teamId,
          cupId
        ).then((res) => {
          resolve(res);
        }, reject);
      }, reject);
    });
  }

  addTeamToCupGroup(teamId: IDType, group: IDType) {
    return TournamentService.addTeamToGroupByIdTournamentGroupTournamentGroupIdTeamTeamIdPost(
      group,
      teamId
    );
  }

  removeCupTeamGroup(teamId: IDType, stageId: IDType) {
    return TournamentService.removeTeamFromGroupTournamentGroupTournamentGroupIdTeamTeamIdDelete(
      stageId,
      teamId
    );
  }

  removeCupTeamCup(teamId: IDType, cupId: IDType) {
    return TournamentService.removeTeamFromTournamentTournamentTournamentIdTeamIdTeamIdDelete(
      teamId,
      cupId
    );
  }

  createCup(cup: TournamentCreate, location?: string) {
    return new CancelablePromise<Tournament>((resolve, reject) => {
      TournamentService.createTournamentTournamentPost(cup).then((newCup) => {
        if (location) {
          LocationService.createLocationLocationPost({
            name: { textDE: location, textEN: location, textIT: location },
            lat: 0,
            long: 0,
          }).then((loc) => {
            TournamentService.patchTournamentTournamentTournamentIdPatch(
              newCup.id,
              {
                locationId: loc.id,
              }
            ).then(resolve, reject);
          });
        } else {
          resolve(newCup);
        }
      }, reject);
    });
  }

  addCupMatchGroup(
    stageId: IDType,
    matchCreate: MatchTournamentGroupCreate,
    location?: string
  ) {
    return new CancelablePromise<MatchTournamentGroup>((resolve, reject) => {
      if (location) {
        LocationService.createLocationLocationPost({
          name: { textDE: location, textEN: location, textIT: location },
          lat: 0,
          long: 0,
        }).then((loc) => {
          MatchService.createMatchTournamentGroupMatchesV2GroupTournamentGroupIdPost(
            stageId,
            {
              ...matchCreate,
              locationId: loc.id,
            }
          ).then(resolve, reject);
        }, reject);
      } else {
        MatchService.createMatchTournamentGroupMatchesV2GroupTournamentGroupIdPost(
          stageId,
          matchCreate
        ).then(resolve, reject);
      }
    });
  }

  connectCupMatchStage(cupId: IDType, matchId: IDType, nextMatchId: IDType) {
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
        matchId,
        {
          nextMatchId,
        }
      ).then(() => {
        TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  addCupMatchStage(
    stageId: IDType,
    matchCreate: MatchTournamentStageCreate,
    location?: string,
    prevMatchId?: IDType
  ) {
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      if (location) {
        LocationService.createLocationLocationPost({
          name: { textDE: location, textEN: location, textIT: location },
          lat: 0,
          long: 0,
        }).then((loc) => {
          MatchService.createMatchTournamentStageMatchesV2StageTournamentStageIdPost(
            stageId,
            {
              ...matchCreate,
              locationId: loc.id,
            }
          ).then((match) => {
            if (prevMatchId) {
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                prevMatchId,
                {
                  nextMatchId: match.id,
                }
              ).then(() => {
                TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
                  match.tournament.id
                ).then(resolve, reject);
              }, reject);
            } else {
              TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
                match.tournament.id
              ).then(resolve, reject);
            }
          }, reject);
        }, reject);
      } else {
        MatchService.createMatchTournamentStageMatchesV2StageTournamentStageIdPost(
          stageId,
          matchCreate
        ).then((match) => {
          if (prevMatchId) {
            MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
              prevMatchId,
              {
                nextMatchId: match.id,
              }
            ).then(() => {
              TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
                match.tournament.id
              ).then(resolve, reject);
            }, reject);
          } else {
            TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
              match.tournament.id
            ).then(resolve, reject);
          }
        }, reject);
      }
    });
  }

  patchCupMatchGroup(
    matchId: IDType,
    matchPatch: MatchTournamentGroupPatch,
    location?: string
  ) {
    return new CancelablePromise<MatchTournamentGroup>((resolve, reject) => {
      if (location) {
        LocationService.createLocationLocationPost({
          name: { textDE: location, textEN: location, textIT: location },
          lat: 0,
          long: 0,
        }).then((loc) => {
          MatchService.patchTournamentGroupMatchMatchesV2TournamentGroupMatchIdPatch(
            matchId,
            {
              ...matchPatch,
              locationId: loc.id,
            }
          ).then(resolve, reject);
        }, reject);
      } else {
        MatchService.patchTournamentGroupMatchMatchesV2TournamentGroupMatchIdPatch(
          matchId,
          matchPatch
        ).then(resolve, reject);
      }
    });
  }

  createQuarters(
    startTime: number,
    matchSectionDurationMinutes: number,
    cupId: IDType,
    twoMatchesAtATime: boolean,
    pauseBetweenMatches: number,
    matchSectionPauseDurationMinutes: number,
    isLooser: boolean
  ) {
    const matchSectionAmount = matchSectionPauseDurationMinutes === 0 ? 1 : 2;
    const matchLengthMs =
      (matchSectionDurationMinutes * matchSectionAmount +
        matchSectionPauseDurationMinutes) *
      MINUTE_IN_MS;
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      TournamentService.createTournamentStageTournamentStageTournamentIdPost(
        cupId,
        {
          name: {
            textDE: "Viertelfinale",
            textIT: "Giro di 8",
            textEN: "Round of 8",
          },
          matchSectionAmount,
          matchSectionDurationMinutes,
          matchSectionPauseDurationMinutes,
          isLosersBracket: isLooser,
        }
      ).then((quert) => {
        TournamentService.createTournamentStageTournamentStageTournamentIdPost(
          cupId,
          {
            name: {
              textDE: "Halbfinale",
              textIT: "Semifinali",
              textEN: "Semifinals",
            },
            matchSectionAmount,
            matchSectionDurationMinutes,
            matchSectionPauseDurationMinutes,
            isLosersBracket: isLooser,
          }
        ).then((semi) => {
          TournamentService.createTournamentStageTournamentStageTournamentIdPost(
            cupId,
            {
              name: {
                textDE: "Finale",
                textIT: "Finali",
                textEN: "Finals",
              },
              matchSectionAmount,
              matchSectionDurationMinutes,
              matchSectionPauseDurationMinutes,
              isLosersBracket: isLooser,
            }
          ).then((final) => {
            client()
              .createStageMatch(
                startTime +
                  (twoMatchesAtATime
                    ? 3 * (matchLengthMs + pauseBetweenMatches)
                    : 6 * (matchLengthMs + pauseBetweenMatches)),
                matchLengthMs,
                "Gewinner S1",
                "Gewinner S2",
                final.id,
                null
              )
              .then((f) => {
                client()
                  .createStageMatch(
                    startTime +
                      (twoMatchesAtATime
                        ? 2 * (matchLengthMs + pauseBetweenMatches)
                        : 4 * (matchLengthMs + pauseBetweenMatches)),
                    matchLengthMs,
                    "Gewinner Q1",
                    "Gewinner Q2",
                    semi.id,
                    f.id
                  )
                  .then((s1) => {
                    client()
                      .createStageMatch(
                        startTime +
                          (twoMatchesAtATime
                            ? 2 * (matchLengthMs + pauseBetweenMatches)
                            : 5 * (matchLengthMs + pauseBetweenMatches)),
                        matchLengthMs,
                        "Gewinner Q3",
                        "Gewinner Q4",
                        semi.id,
                        f.id
                      )
                      .then((s2) => {
                        client()
                          .createStageMatch(
                            startTime,
                            matchLengthMs,
                            "Gewinner A",
                            "Zweiter B",
                            quert.id,
                            s1.id
                          )
                          .then(() => {
                            client()
                              .createStageMatch(
                                startTime +
                                  (twoMatchesAtATime
                                    ? 0
                                    : matchLengthMs + pauseBetweenMatches),
                                matchLengthMs,
                                "Gewinner B",
                                "Zweiter A",
                                quert.id,
                                s1.id
                              )
                              .then(() => {
                                client()
                                  .createStageMatch(
                                    startTime +
                                      (twoMatchesAtATime
                                        ? matchLengthMs + pauseBetweenMatches
                                        : 2 *
                                          (matchLengthMs +
                                            pauseBetweenMatches)),
                                    matchLengthMs,
                                    "Gewinner C",
                                    "Zweiter D",
                                    quert.id,
                                    s2.id
                                  )
                                  .then(() => {
                                    client()
                                      .createStageMatch(
                                        startTime +
                                          (twoMatchesAtATime
                                            ? matchLengthMs +
                                              pauseBetweenMatches
                                            : 3 *
                                              (matchLengthMs +
                                                pauseBetweenMatches)),
                                        matchLengthMs,
                                        "Gewinner D",
                                        "Zweiter C",
                                        quert.id,
                                        s2.id
                                      )
                                      .then(() => {
                                        TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
                                          cupId
                                        ).then(resolve, reject);
                                      }, reject);
                                  }, reject);
                              }, reject);
                          }, reject);
                      }, reject);
                  }, reject);
              }, reject);
          }, reject);
        }, reject);
      }, reject);
    });
  }

  createEighters(
    startTime: number,
    matchSectionDurationMinutes: number,
    cupId: IDType,
    twoMatchesAtATime: boolean,
    pauseBetweenMatches: number,
    matchSectionPauseDurationMinutes: number,
    isLooser: boolean
  ) {
    const matchSectionAmount = matchSectionPauseDurationMinutes === 0 ? 1 : 2;
    const matchLengthMs =
      (matchSectionDurationMinutes * matchSectionAmount +
        matchSectionPauseDurationMinutes) *
      MINUTE_IN_MS;
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      TournamentService.createTournamentStageTournamentStageTournamentIdPost(
        cupId,
        {
          name: {
            textDE: "Achtelfinale",
            textIT: "Giro di 16",
            textEN: "Round of 16",
          },
          matchSectionAmount,
          matchSectionDurationMinutes,
          matchSectionPauseDurationMinutes,
          isLosersBracket: isLooser,
        }
      ).then((eight) => {
        client()
          .createQuarters(
            startTime +
              (twoMatchesAtATime
                ? 4 * (matchLengthMs + pauseBetweenMatches)
                : 8 * (matchLengthMs + pauseBetweenMatches)),
            matchSectionDurationMinutes,
            cupId,
            twoMatchesAtATime,
            pauseBetweenMatches,
            matchSectionPauseDurationMinutes,
            isLooser
          )
          .then((stages) => {
            const quart = stages.find((s) => s.matches.length === 4);
            if (quart) {
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                quart.matches[0].id,
                {
                  team1Placeholder: "Gewinner A1",
                  team2Placeholder: "Gewinner A2",
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                quart.matches[1].id,
                {
                  team1Placeholder: "Gewinner A3",
                  team2Placeholder: "Gewinner A4",
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                quart.matches[2].id,
                {
                  team1Placeholder: "Gewinner A5",
                  team2Placeholder: "Gewinner A6",
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                quart.matches[3].id,
                {
                  team1Placeholder: "Gewinner A7",
                  team2Placeholder: "Gewinner A8",
                }
              );
              client()
                .createStageMatch(
                  startTime,
                  matchLengthMs,
                  "Gewinner A",
                  "Zweiter B",
                  eight.id,
                  quart.matches[0].id
                )
                .then(() => {
                  client()
                    .createStageMatch(
                      startTime +
                        (twoMatchesAtATime
                          ? 0
                          : matchLengthMs + pauseBetweenMatches),
                      matchLengthMs,
                      "Gewinner B",
                      "Zweiter A",
                      eight.id,
                      quart.matches[0].id
                    )
                    .then(() => {
                      client()
                        .createStageMatch(
                          startTime +
                            (twoMatchesAtATime
                              ? matchLengthMs + pauseBetweenMatches
                              : 2 * (matchLengthMs + pauseBetweenMatches)),
                          matchLengthMs,
                          "Gewinner C",
                          "Zweiter D",
                          eight.id,
                          quart.matches[1].id
                        )
                        .then(() => {
                          client()
                            .createStageMatch(
                              startTime +
                                (twoMatchesAtATime
                                  ? matchLengthMs + pauseBetweenMatches
                                  : 3 * (matchLengthMs + pauseBetweenMatches)),
                              matchLengthMs,
                              "Gewinner D",
                              "Zweiter C",
                              eight.id,
                              quart.matches[1].id
                            )
                            .then(() => {
                              client()
                                .createStageMatch(
                                  startTime +
                                    (twoMatchesAtATime
                                      ? 2 *
                                        (matchLengthMs + pauseBetweenMatches)
                                      : 4 *
                                        (matchLengthMs + pauseBetweenMatches)),
                                  matchLengthMs,
                                  "Gewinner E",
                                  "Zweiter F",
                                  eight.id,
                                  quart.matches[2].id
                                )
                                .then(() => {
                                  client()
                                    .createStageMatch(
                                      startTime +
                                        (twoMatchesAtATime
                                          ? 2 *
                                            (matchLengthMs +
                                              pauseBetweenMatches)
                                          : 5 *
                                            (matchLengthMs +
                                              pauseBetweenMatches)),
                                      matchLengthMs,
                                      "Gewinner F",
                                      "Zweiter E",
                                      eight.id,
                                      quart.matches[2].id
                                    )
                                    .then(() => {
                                      client()
                                        .createStageMatch(
                                          startTime +
                                            (twoMatchesAtATime
                                              ? 3 *
                                                (matchLengthMs +
                                                  pauseBetweenMatches)
                                              : 6 *
                                                (matchLengthMs +
                                                  pauseBetweenMatches)),
                                          matchLengthMs,
                                          "Gewinner G",
                                          "Zweiter H",
                                          eight.id,
                                          quart.matches[3].id
                                        )
                                        .then(() => {
                                          client()
                                            .createStageMatch(
                                              startTime +
                                                (twoMatchesAtATime
                                                  ? 3 *
                                                    (matchLengthMs +
                                                      pauseBetweenMatches)
                                                  : 7 *
                                                    (matchLengthMs +
                                                      pauseBetweenMatches)),
                                              matchLengthMs,
                                              "Gewinner H",
                                              "Zweiter G",
                                              eight.id,
                                              quart.matches[3].id
                                            )
                                            .then(() => {
                                              TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
                                                cupId
                                              ).then(resolve, reject);
                                            }, reject);
                                        }, reject);
                                    }, reject);
                                }, reject);
                            }, reject);
                        }, reject);
                    }, reject);
                }, reject);
            } else {
              reject();
            }
          }, reject);
      });
    });
  }

  createSixteenth(
    startTime: number,
    matchSectionDurationMinutes: number,
    cupId: IDType,
    twoMatchesAtATime: boolean,
    pauseBetweenMatches: number,
    matchSectionPauseDurationMinutes: number,
    isLooser: boolean
  ) {
    const matchSectionAmount = matchSectionPauseDurationMinutes === 0 ? 1 : 2;
    const matchLengthMs =
      (matchSectionDurationMinutes * matchSectionAmount +
        matchSectionPauseDurationMinutes) *
      MINUTE_IN_MS;
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      TournamentService.createTournamentStageTournamentStageTournamentIdPost(
        cupId,
        {
          name: {
            textDE: "Sechzehntelfinale",
            textIT: "Giro di 32",
            textEN: "Round of 32",
          },
          matchSectionAmount,
          matchSectionDurationMinutes,
          matchSectionPauseDurationMinutes,
          isLosersBracket: isLooser,
        }
      ).then((sixteenth) => {
        client()
          .createEighters(
            startTime,
            matchSectionDurationMinutes,
            cupId,
            twoMatchesAtATime,
            pauseBetweenMatches,
            matchSectionPauseDurationMinutes,
            isLooser
          )
          .then((stages) => {
            const eight = stages.find((s) => s.matches.length === 8);
            if (eight) {
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[0].id,
                {
                  team1Placeholder: "Gewinner 16'1",
                  team2Placeholder: "Gewinner 16'2",
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[1].id,
                {
                  team1Placeholder: "Gewinner 16'3",
                  team2Placeholder: "Gewinner 16'4",
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[2].id,
                {
                  team1Placeholder: "Gewinner 16'5",
                  team2Placeholder: "Gewinner 16'6",
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[3].id,
                {
                  team1Placeholder: "Gewinner 16'7",
                  team2Placeholder: "Gewinner 16'8",
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[4].id,
                {
                  team1Placeholder: "Gewinner 16'9",
                  team2Placeholder: "Gewinner 16'10",
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[5].id,
                {
                  team1Placeholder: "Gewinner 16'11",
                  team2Placeholder: "Gewinner 16'12",
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[6].id,
                {
                  team1Placeholder: "Gewinner 16'13",
                  team2Placeholder: "Gewinner 16'14",
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[7].id,
                {
                  team1Placeholder: "Gewinner 16'15",
                  team2Placeholder: "Gewinner 16'16",
                }
              );
              client()
                .createStageMatch(
                  startTime,
                  matchLengthMs,
                  "Gewinner A",
                  "Zweiter B",
                  sixteenth.id,
                  eight.matches[0].id
                )
                .then(() => {
                  client()
                    .createStageMatch(
                      startTime +
                        (twoMatchesAtATime
                          ? 0
                          : matchLengthMs + pauseBetweenMatches),
                      matchLengthMs,
                      "Gewinner B",
                      "Zweiter A",
                      sixteenth.id,
                      eight.matches[0].id
                    )
                    .then(() => {
                      client()
                        .createStageMatch(
                          startTime +
                            (twoMatchesAtATime
                              ? matchLengthMs + pauseBetweenMatches
                              : 2 * (matchLengthMs + pauseBetweenMatches)),
                          matchLengthMs,
                          "Gewinner C",
                          "Zweiter D",
                          sixteenth.id,
                          eight.matches[1].id
                        )
                        .then(() => {
                          client()
                            .createStageMatch(
                              startTime +
                                (twoMatchesAtATime
                                  ? matchLengthMs + pauseBetweenMatches
                                  : 3 * (matchLengthMs + pauseBetweenMatches)),
                              matchLengthMs,
                              "Gewinner D",
                              "Zweiter C",
                              sixteenth.id,
                              eight.matches[1].id
                            )
                            .then(() => {
                              client()
                                .createStageMatch(
                                  startTime +
                                    (twoMatchesAtATime
                                      ? 2 *
                                        (matchLengthMs + pauseBetweenMatches)
                                      : 4 *
                                        (matchLengthMs + pauseBetweenMatches)),
                                  matchLengthMs,
                                  "Gewinner E",
                                  "Zweiter F",
                                  sixteenth.id,
                                  eight.matches[2].id
                                )
                                .then(() => {
                                  client()
                                    .createStageMatch(
                                      startTime +
                                        (twoMatchesAtATime
                                          ? 2 *
                                            (matchLengthMs +
                                              pauseBetweenMatches)
                                          : 5 *
                                            (matchLengthMs +
                                              pauseBetweenMatches)),
                                      matchLengthMs,
                                      "Gewinner F",
                                      "Zweiter E",
                                      sixteenth.id,
                                      eight.matches[2].id
                                    )
                                    .then(() => {
                                      client()
                                        .createStageMatch(
                                          startTime +
                                            (twoMatchesAtATime
                                              ? 3 *
                                                (matchLengthMs +
                                                  pauseBetweenMatches)
                                              : 6 *
                                                (matchLengthMs +
                                                  pauseBetweenMatches)),
                                          matchLengthMs,
                                          "Gewinner G",
                                          "Zweiter H",
                                          sixteenth.id,
                                          eight.matches[3].id
                                        )
                                        .then(() => {
                                          client()
                                            .createStageMatch(
                                              startTime +
                                                (twoMatchesAtATime
                                                  ? 3 *
                                                    (matchLengthMs +
                                                      pauseBetweenMatches)
                                                  : 7 *
                                                    (matchLengthMs +
                                                      pauseBetweenMatches)),
                                              matchLengthMs,
                                              "Gewinner H",
                                              "Zweiter G",
                                              sixteenth.id,
                                              eight.matches[3].id
                                            )
                                            .then(() => {
                                              client()
                                                .createStageMatch(
                                                  startTime,
                                                  matchLengthMs,
                                                  "Gewinner I",
                                                  "Zweiter J",
                                                  sixteenth.id,
                                                  eight.matches[4].id
                                                )
                                                .then(() => {
                                                  client()
                                                    .createStageMatch(
                                                      startTime +
                                                        (twoMatchesAtATime
                                                          ? 0
                                                          : matchLengthMs +
                                                            pauseBetweenMatches),
                                                      matchLengthMs,
                                                      "Gewinner J",
                                                      "Zweiter I",
                                                      sixteenth.id,
                                                      eight.matches[4].id
                                                    )
                                                    .then(() => {
                                                      client()
                                                        .createStageMatch(
                                                          startTime +
                                                            (twoMatchesAtATime
                                                              ? matchLengthMs +
                                                                pauseBetweenMatches
                                                              : 2 *
                                                                (matchLengthMs +
                                                                  pauseBetweenMatches)),
                                                          matchLengthMs,
                                                          "Gewinner K",
                                                          "Zweiter L",
                                                          sixteenth.id,
                                                          eight.matches[5].id
                                                        )
                                                        .then(() => {
                                                          client()
                                                            .createStageMatch(
                                                              startTime +
                                                                (twoMatchesAtATime
                                                                  ? matchLengthMs +
                                                                    pauseBetweenMatches
                                                                  : 3 *
                                                                    (matchLengthMs +
                                                                      pauseBetweenMatches)),
                                                              matchLengthMs,
                                                              "Gewinner L",
                                                              "Zweiter K",
                                                              sixteenth.id,
                                                              eight.matches[5]
                                                                .id
                                                            )
                                                            .then(() => {
                                                              client()
                                                                .createStageMatch(
                                                                  startTime +
                                                                    (twoMatchesAtATime
                                                                      ? 2 *
                                                                        (matchLengthMs +
                                                                          pauseBetweenMatches)
                                                                      : 4 *
                                                                        (matchLengthMs +
                                                                          pauseBetweenMatches)),
                                                                  matchLengthMs,
                                                                  "Gewinner M",
                                                                  "Zweiter N",
                                                                  sixteenth.id,
                                                                  eight
                                                                    .matches[6]
                                                                    .id
                                                                )
                                                                .then(() => {
                                                                  client()
                                                                    .createStageMatch(
                                                                      startTime +
                                                                        (twoMatchesAtATime
                                                                          ? 2 *
                                                                            (matchLengthMs +
                                                                              pauseBetweenMatches)
                                                                          : 5 *
                                                                            (matchLengthMs +
                                                                              pauseBetweenMatches)),
                                                                      matchLengthMs,
                                                                      "Gewinner N",
                                                                      "Zweiter M",
                                                                      sixteenth.id,
                                                                      eight
                                                                        .matches[6]
                                                                        .id
                                                                    )
                                                                    .then(
                                                                      () => {
                                                                        client()
                                                                          .createStageMatch(
                                                                            startTime +
                                                                              (twoMatchesAtATime
                                                                                ? 3 *
                                                                                  (matchLengthMs +
                                                                                    pauseBetweenMatches)
                                                                                : 6 *
                                                                                  (matchLengthMs +
                                                                                    pauseBetweenMatches)),
                                                                            matchLengthMs,
                                                                            "Gewinner O",
                                                                            "Zweiter P",
                                                                            sixteenth.id,
                                                                            eight
                                                                              .matches[7]
                                                                              .id
                                                                          )
                                                                          .then(
                                                                            () => {
                                                                              client()
                                                                                .createStageMatch(
                                                                                  startTime +
                                                                                    (twoMatchesAtATime
                                                                                      ? 3 *
                                                                                        (matchLengthMs +
                                                                                          pauseBetweenMatches)
                                                                                      : 7 *
                                                                                        (matchLengthMs +
                                                                                          pauseBetweenMatches)),
                                                                                  matchLengthMs,
                                                                                  "Gewinner P",
                                                                                  "Zweiter O",
                                                                                  sixteenth.id,
                                                                                  eight
                                                                                    .matches[7]
                                                                                    .id
                                                                                )
                                                                                .then(
                                                                                  () => {
                                                                                    TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
                                                                                      cupId
                                                                                    ).then(
                                                                                      resolve,
                                                                                      reject
                                                                                    );
                                                                                  },
                                                                                  reject
                                                                                );
                                                                            },
                                                                            reject
                                                                          );
                                                                      },
                                                                      reject
                                                                    );
                                                                }, reject);
                                                            }, reject);
                                                        }, reject);
                                                    }, reject);
                                                }, reject);
                                            }, reject);
                                        }, reject);
                                    }, reject);
                                }, reject);
                            }, reject);
                        }, reject);
                    }, reject);
                }, reject);
            } else {
              reject();
            }
          }, reject);
      }, reject);
    });
  }
}

const client = Client.getInstance;
export default client;
export { Client as StaticClient };

// rel oad
