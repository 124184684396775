import { Reducer } from "redux";
import {
  mapToMatchSmall,
  mapToMatchSmallSmall,
  replaceOrAddResponseMultiple,
  replaceOrAddResponseSingle,
  toMatchTournamentMinimal,
} from "../../config/utils";
import { AllActions, CupActionTypes } from "../actions/types";
import { CupState, ValueStore } from "../constants";
import { TournamentGroupStanding, TournamentStanding } from "../../client/api";
import { MatchSmallElement } from "../../config/types";

const initialState: CupState = {
  cup: null,
  cups: null,
  standings: [],
  stages: [],
  groups: [],
  match: null,
  matchesSmall: [],
  topScorer: [],
  secrets: [],
  squad: [],
  team: [],
  status: "idle",
  stagesStatus: "idle",
  groupsStatus: "idle",
  matchesStatus: "idle",
  standingsStatus: "idle",
  topScorerStatus: "idle",
  secretsStatus: "idle",
  squadStatus: "idle",
  teamStatus: "idle",
  eventsStatus: "idle",
  error: null,
  stagesError: null,
  groupsError: null,
  matchesError: null,
  standingsError: null,
  topScorerError: null,
  secretsError: null,
  squadError: null,
  teamError: null,
  eventsError: null,
};

const reducer: Reducer<CupState, AllActions> = (
  state = initialState,
  action
) => {
  let { matchesSmall } = state;
  const cu = state.cup;
  switch (action.type) {
    case CupActionTypes.FETCH_CUPS:
    case CupActionTypes.FETCH_CUP:
      return {
        ...state,
        error: null,
        status: "loading",
      };
    case CupActionTypes.FETCH_CUP_NEW:
      return {
        ...state,
        cup: null,
        error: null,
        stages: [],
        groups: [],
        standings: [],
        matches: [],
        status: "loading",
      };
    case CupActionTypes.FETCH_CUPS_SUCCESS:
      return {
        ...state,
        status: "idle",
        cups: action.payload,
      };
    case CupActionTypes.FETCH_STAGES:
      return {
        ...state,
        stagesError: null,
        stagesStatus: "loading",
      };
    case CupActionTypes.FETCH_GROUPS:
      return {
        ...state,
        groupsError: null,
        groupsStatus: "loading",
      };
    case CupActionTypes.POST_EVENT:
      return {
        ...state,
        eventsError: null,
        eventsStatus: "loading",
      };
    case CupActionTypes.FETCH_MATCH:
      return {
        ...state,
        matchesError: null,
        matchesStatus: "loading",
      };
    case CupActionTypes.FETCH_CUPS_ERROR:
    case CupActionTypes.FETCH_CUP_ERROR:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };

    case CupActionTypes.FETCH_STAGES_ERROR:
      return {
        ...state,
        stagesStatus: "failed",
        stagesError: action.payload,
      };
    case CupActionTypes.FETCH_GROUPS_ERROR:
      return {
        ...state,
        groupsStatus: "failed",
        groupsError: action.payload,
      };
    case CupActionTypes.POST_EVENT_ERROR:
      return {
        ...state,
        eventsStatus: "failed",
        eventsError: action.payload,
      };
    case CupActionTypes.FETCH_MATCH_ERROR:
      return {
        ...state,
        matchesStatus: "failed",
        matchesError: action.payload,
      };
    case CupActionTypes.FETCH_CUP_SUCCESS:
      const st = state.standings.find((s) => s.id === action.payload.id);
      if (st) {
        const { teams } = action.payload;
        const standings: ValueStore<TournamentGroupStanding[]> = {
          ...st,
          response: st.response.map<TournamentGroupStanding>((stand) => ({
            ...stand,
            standings: stand.standings.map<TournamentStanding>((single) => ({
              ...single,
              team: teams.find((t) => t.id === single.team.id) ?? single.team,
            })),
          })),
        };
        return {
          ...state,
          status: "idle",
          cup: action.payload,
          standings: replaceOrAddResponseSingle(standings, state.standings),
        };
      }
      return {
        ...state,
        status: "idle",
        cup: action.payload,
      };
    case CupActionTypes.FETCH_STAGE_SUCCESS: {
      if (cu) {
        const matches = state.matchesSmall.find(
          (m) => m.id === action.payload.id
        );
        const newMatches = action.payload.response.matches.map((m) =>
          mapToMatchSmall(m, cu)
        );
        if (matches) {
          matches.response = replaceOrAddResponseMultiple(
            newMatches,
            matches.response
          );
          matchesSmall = replaceOrAddResponseSingle(
            matches,
            state.matchesSmall
          );
        } else {
          matchesSmall = matchesSmall.concat([
            {
              id: action.payload.id,
              fetchDate: new Date(),
              date: undefined,
              response: newMatches,
            },
          ]);
        }
      }
      return {
        ...state,
        stagesStatus: "idle",
        stages: replaceOrAddResponseSingle(
          action.payload.response,
          state.stages
        ),
        matchesSmall,
      };
    }
    case CupActionTypes.FETCH_STAGES_SUCCESS:
      if (cu) {
        const matches = state.matchesSmall.find(
          (m) => m.id === action.payload.id
        );
        const newMatches = action.payload.response.reduce<MatchSmallElement[]>(
          (p, c) => p.concat(c.matches.map((m) => mapToMatchSmall(m, cu))),
          []
        );
        if (matches) {
          matches.response = replaceOrAddResponseMultiple(
            newMatches,
            matches.response
          );
          matchesSmall = replaceOrAddResponseSingle(
            matches,
            state.matchesSmall
          );
        } else {
          matchesSmall = matchesSmall.concat([
            {
              id: action.payload.id,
              fetchDate: new Date(),
              date: undefined,
              response: newMatches,
            },
          ]);
        }
      }
      return {
        ...state,
        stagesStatus: "idle",
        stages: action.payload.response,
        matchesSmall,
      };
    case CupActionTypes.FETCH_GROUP_SUCCESS: {
      const { cup } = state;
      if (cup) {
        const matches = state.matchesSmall.find(
          (m) => m.id === action.payload.id
        );
        const newMatches = action.payload.response.matches.map((m) =>
          mapToMatchSmall(m, cup)
        );
        if (matches) {
          matches.response = replaceOrAddResponseMultiple(
            newMatches,
            matches.response
          );
          matchesSmall = replaceOrAddResponseSingle(
            matches,
            state.matchesSmall
          );
        } else {
          matchesSmall = matchesSmall.concat([
            {
              id: action.payload.id,
              fetchDate: new Date(),
              date: undefined,
              response: newMatches,
            },
          ]);
        }
      }
      return {
        ...state,
        groupsStatus: "idle",
        groups: replaceOrAddResponseSingle(
          action.payload.response,
          state.groups
        ),
        matchesSmall,
      };
    }
    case CupActionTypes.FETCH_GROUPS_SUCCESS:
      const { cup } = state;
      if (cup) {
        const matches = state.matchesSmall.find(
          (m) => m.id === action.payload.id
        );

        const newMatches = action.payload.response.reduce<MatchSmallElement[]>(
          (p, c) => p.concat(c.matches.map((m) => mapToMatchSmall(m, cup))),
          []
        );
        if (matches) {
          matches.response = replaceOrAddResponseMultiple(
            newMatches,
            matches.response
          );
          matchesSmall = replaceOrAddResponseSingle(
            matches,
            state.matchesSmall
          );
        } else {
          matchesSmall = matchesSmall.concat([
            {
              id: action.payload.id,
              fetchDate: new Date(),
              date: undefined,
              response: newMatches,
            },
          ]);
        }
      }
      return {
        ...state,
        groupsStatus: "idle",
        groups: action.payload.response,
        matchesSmall,
      };
    case CupActionTypes.FETCH_STAGE_MATCH_SUCCESS:
      const cup1 = state.cup;
      if (cup1) {
        const matches = state.matchesSmall.find(
          (m) => m.id === action.payload.id
        );
        const newMatch = mapToMatchSmall(action.payload.response, cup1);
        if (matches) {
          matches.response = replaceOrAddResponseSingle(
            newMatch,
            matches.response
          );
          matchesSmall = replaceOrAddResponseSingle(
            matches,
            state.matchesSmall
          );
        } else {
          matchesSmall = matchesSmall.concat([
            {
              id: action.payload.id,
              fetchDate: new Date(),
              date: undefined,
              response: [newMatch],
            },
          ]);
        }
      }
      return {
        ...state,
        stagesStatus: "idle",
        stages: state.stages.map((s) =>
          s.id === action.payload.date
            ? {
                ...s,
                matches: replaceOrAddResponseSingle(
                  toMatchTournamentMinimal(action.payload.response),
                  s.matches
                ),
              }
            : s
        ),
        matchesSmall,
        match:
          state.match?.id === action.payload.response.id
            ? action.payload.response
            : state.match,
      };
    case CupActionTypes.FETCH_GROUP_MATCH_SUCCESS:
      const cup2 = state.cup;
      if (cup2) {
        const matches = state.matchesSmall.find(
          (m) => m.id === action.payload.id
        );
        const newMatch = mapToMatchSmall(action.payload.response, cup2);
        if (matches) {
          matches.response = replaceOrAddResponseSingle(
            newMatch,
            matches.response
          );
          matchesSmall = replaceOrAddResponseSingle(
            matches,
            state.matchesSmall
          );
        } else {
          matchesSmall = matchesSmall.concat([
            {
              id: action.payload.id,
              fetchDate: new Date(),
              date: undefined,
              response: [newMatch],
            },
          ]);
        }
      }
      return {
        ...state,
        groupsStatus: "idle",
        groups: state.groups.map((s) =>
          s.id === action.payload.date
            ? {
                ...s,
                matches: replaceOrAddResponseSingle(
                  toMatchTournamentMinimal(action.payload.response),
                  s.matches
                ),
              }
            : s
        ),
        matchesSmall,
        match:
          state.match?.id === action.payload.response.id
            ? action.payload.response
            : state.match,
      };
    case CupActionTypes.FETCH_MATCHES_SUCCESS:
      return {
        ...state,
        matchesStatus: "idle",
        matchesSmall: replaceOrAddResponseSingle(
          {
            ...action.payload,
            response: action.payload.response.map((m) =>
              "leagueTournamentId" in m
                ? mapToMatchSmallSmall(m)
                : mapToMatchSmall(m, m.tournament)
            ),
          },
          state.matchesSmall
        ),
      };
    case CupActionTypes.REFRESH_MATCH_SUCCESS:
      const tMatches = matchesSmall.find(
        (vs) => vs.id === action.payload.tournament.id
      );
      if (tMatches) {
        tMatches.response = replaceOrAddResponseSingle(
          mapToMatchSmall(action.payload, action.payload.tournament),
          tMatches.response
        );
        return {
          ...state,
          matchesStatus: "idle",
          matchesSmall: replaceOrAddResponseSingle(tMatches, matchesSmall),
          match:
            state.match?.id === action.payload.id
              ? action.payload
              : state.match,
        };
      }
      return {
        ...state,
        matchesStatus: "idle",
        match:
          state.match?.id === action.payload.id ? action.payload : state.match,
      };
    case CupActionTypes.FETCH_MATCH_SUCCESS:
      return {
        ...state,
        matchesStatus: "idle",
        match: action.payload,
      };
    case CupActionTypes.RESET_TWICE:
      return {
        ...state,
        matchesStatus: "idle",
        matchesError: null,
      };
    case CupActionTypes.POST_EVENT_SUCCESS:
      const mSmall = matchesSmall.find((vs) => vs.id === action.payload.date);
      if (mSmall) {
        mSmall.response = mSmall.response.map((m) =>
          m.id === action.payload.id
            ? {
                ...m,
                goal1:
                  m.goal1 +
                  (action.payload.response.teamId === m.team1.id &&
                  action.payload.response.type === "GOAL"
                    ? 1
                    : 0),
                goal2:
                  m.goal2 +
                  (action.payload.response.teamId === m.team2.id &&
                  action.payload.response.type === "GOAL"
                    ? 1
                    : 0),
              }
            : m
        );
      }
      return {
        ...state,
        eventsStatus: "idle",
        eventsError: null,
        matchesSmall: mSmall
          ? replaceOrAddResponseSingle(mSmall, state.matchesSmall)
          : state.matchesSmall,
        match:
          state.match && state.match.id === action.payload.id
            ? {
                ...state.match,
                goal1:
                  state.match.goal1 +
                  (action.payload.response.teamId === state.match.team1?.id &&
                  action.payload.response.type === "GOAL"
                    ? 1
                    : 0),
                goal2:
                  state.match.goal2 +
                  (action.payload.response.teamId === state.match.team2?.id &&
                  action.payload.response.type === "GOAL"
                    ? 1
                    : 0),
                events: replaceOrAddResponseSingle(
                  action.payload.response,
                  state.match.events
                ),
              }
            : state.match,
      };
    case CupActionTypes.DELETE_EVENT_SUCCESS:
      const ma =
        state.match && state.match && state.match.id === action.payload.id
          ? state.match
          : null;
      const ev = ma
        ? ma.events.find((e) => e.id === action.payload.response)
        : null;
      return {
        ...state,
        eventsStatus: "idle",
        eventsError: null,
        match:
          ma && ev
            ? {
                ...ma,
                goal1:
                  ma.goal1 -
                  (ev.teamId === ma.team1?.id && ev.type === "GOAL" ? 1 : 0),
                goal2:
                  ma.goal2 -
                  (ev.teamId === ma.team2?.id && ev.type === "GOAL" ? 1 : 0),
                events: ma.events.filter(
                  (e) => e.id !== action.payload.response
                ),
              }
            : state.match,
      };
    case CupActionTypes.FETCH_STANDINGS:
      return {
        ...state,
        standingsError: null,
        standingsStatus: "loading",
      };
    case CupActionTypes.FETCH_STANDINGS_SUCCESS:
      return {
        ...state,
        standingsStatus: "idle",
        standings: replaceOrAddResponseSingle(action.payload, state.standings),
      };
    case CupActionTypes.FETCH_STANDINGS_SUCCESS_MULTIPLE:
      return {
        ...state,
        standingsStatus: "idle",
        standings: action.payload,
      };
    case CupActionTypes.FETCH_STANDINGS_ERROR:
      return {
        ...state,
        standingsStatus: "failed",
        standingsError: action.payload,
      };
    case CupActionTypes.FETCH_TOP_SCORER:
      return {
        ...state,
        topScorerStatus: "loading",
        topScorerError: null,
      };
    case CupActionTypes.FETCH_TOP_SCORER_ERROR:
      return {
        ...state,
        topScorerStatus: "failed",
        topScorerError: action.payload,
      };
    case CupActionTypes.FETCH_TOP_SCORER_SUCCESS:
      return {
        ...state,
        topScorerStatus: "idle",
        topScorer: replaceOrAddResponseSingle(action.payload, state.topScorer),
      };
    case CupActionTypes.FETCH_SECRETS: {
      return {
        ...state,
        secretsStatus: "loading",
        secretsError: null,
      };
    }
    case CupActionTypes.FETCH_SECRETS_SUCCESS: {
      return {
        ...state,
        secretsStatus: "idle",
        secrets: replaceOrAddResponseSingle(action.payload, state.secrets),
      };
    }
    case CupActionTypes.FETCH_SECRETS_ERROR: {
      return {
        ...state,
        secretsStatus: "failed",
        secretsError: action.payload,
      };
    }

    case CupActionTypes.FETCH_SQUAD_ERROR: {
      return {
        ...state,
        squadStatus: "failed",
        squadError: action.payload,
      };
    }
    case CupActionTypes.FETCH_SQUAD: {
      return {
        ...state,
        squadStatus: "loading",
        squadError: null,
      };
    }
    case CupActionTypes.FETCH_SQUAD_SUCCESS: {
      return {
        ...state,
        squadStatus: "idle",
        squad: replaceOrAddResponseSingle(action.payload, state.squad),
      };
    }
    case CupActionTypes.FETCH_TEAM_SUCCESS: {
      return {
        ...state,
        teamStatus: "idle",
        team: replaceOrAddResponseSingle(action.payload, state.team),
      };
    }
    case CupActionTypes.FETCH_TEAM_ERROR: {
      return {
        ...state,
        teamStatus: "failed",
        teamError: action.payload,
      };
    }
    case CupActionTypes.FETCH_TEAM: {
      return {
        ...state,
        teamStatus: "loading",
        teamError: null,
      };
    }
    case CupActionTypes.RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as cupReducer };
