import React from "react";
import { Image } from "../../../../client/api";
import ClavaImage from "../../ClavaImage";

const Field: React.FC<{ team1: Image; team2: Image }> = ({ team1, team2 }) => (
  <div className="relative w-[618px] h-[345px] mx-auto flex flex-row items-center justify-center">
    <svg
      className="w-[618px] h-[345px]"
      viewBox="0 0 618 345"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.657227 344.165L0.657212 6.10352e-05L617.058 3.40914e-05L617.058 344.165L0.657227 344.165Z"
        fill="#00AD5A"
      />
      <rect
        x="536.936"
        y="344.165"
        width="344.165"
        height="40.972"
        transform="rotate(-90 536.936 344.165)"
        fill="#00944D"
      />
      <rect
        x="208.249"
        y="344.165"
        width="344.165"
        height="40.972"
        transform="rotate(-90 208.249 344.165)"
        fill="#00944D"
      />
      <rect
        x="454.081"
        y="344.165"
        width="344.165"
        height="41.8825"
        transform="rotate(-90 454.081 344.165)"
        fill="#00944D"
      />
      <rect
        x="125.394"
        y="344.165"
        width="344.165"
        height="41.8825"
        transform="rotate(-90 125.394 344.165)"
        fill="#00944D"
      />
      <rect
        x="373.048"
        y="344.165"
        width="344.165"
        height="41.8825"
        transform="rotate(-90 373.048 344.165)"
        fill="#00944D"
      />
      <rect
        x="44.3604"
        y="344.165"
        width="344.165"
        height="41.8825"
        transform="rotate(-90 44.3604 344.165)"
        fill="#00944D"
      />
      <rect
        x="290.192"
        y="344.165"
        width="344.165"
        height="40.972"
        transform="rotate(-90 290.192 344.165)"
        fill="#00944D"
      />
      <rect
        x="14.3145"
        y="11.8363"
        width="0.910489"
        height="592.728"
        transform="rotate(-90 14.3145 11.8363)"
        fill="white"
      />
      <rect
        x="13.4043"
        y="333.239"
        width="0.910489"
        height="592.728"
        transform="rotate(-90 13.4043 333.239)"
        fill="white"
      />
      <rect
        x="310.224"
        y="332.328"
        width="321.403"
        height="0.910489"
        transform="rotate(-90 310.224 332.328)"
        fill="white"
      />
      <rect
        x="606.132"
        y="331.418"
        width="320.492"
        height="0.910489"
        transform="rotate(-90 606.132 331.418)"
        fill="white"
      />
      <rect
        width="320.492"
        height="0.910489"
        transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 10.6729 332.328)"
        fill="white"
      />
      <circle
        cx="309.313"
        cy="171.172"
        r="44.114"
        transform="rotate(-90 309.313 171.172)"
        stroke="white"
      />
      <mask id="path-15-inside-1_0_1" fill="white">
        <path d="M607.043 322.313C605.608 322.313 604.187 322.596 602.862 323.145C601.536 323.694 600.332 324.499 599.317 325.513C598.303 326.528 597.498 327.732 596.949 329.058C596.4 330.383 596.117 331.804 596.117 333.239L607.043 333.239L607.043 322.313Z" />
      </mask>
      <path
        d="M607.043 322.313C605.608 322.313 604.187 322.596 602.862 323.145C601.536 323.694 600.332 324.499 599.317 325.513C598.303 326.528 597.498 327.732 596.949 329.058C596.4 330.383 596.117 331.804 596.117 333.239L607.043 333.239L607.043 322.313Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#path-15-inside-1_0_1)"
      />
      <mask id="path-16-inside-2_0_1" fill="white">
        <path d="M9.76261 322.313C11.1974 322.313 12.6182 322.596 13.9438 323.145C15.2693 323.694 16.4738 324.499 17.4884 325.513C18.5029 326.528 19.3077 327.732 19.8568 329.058C20.4059 330.383 20.6885 331.804 20.6885 333.239L9.76261 333.239L9.76261 322.313Z" />
      </mask>
      <path
        d="M9.76261 322.313C11.1974 322.313 12.6182 322.596 13.9438 323.145C15.2693 323.694 16.4738 324.499 17.4884 325.513C18.5029 326.528 19.3077 327.732 19.8568 329.058C20.4059 330.383 20.6885 331.804 20.6885 333.239L9.76261 333.239L9.76261 322.313Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#path-16-inside-2_0_1)"
      />
      <mask id="path-17-inside-3_0_1" fill="white">
        <path d="M607.043 21.8517C605.608 21.8517 604.187 21.5691 602.862 21.0201C601.536 20.471 600.332 19.6662 599.317 18.6516C598.303 17.6371 597.498 16.4326 596.949 15.107C596.4 13.7814 596.117 12.3607 596.117 10.9259L607.043 10.9259L607.043 21.8517Z" />
      </mask>
      <path
        d="M607.043 21.8517C605.608 21.8517 604.187 21.5691 602.862 21.0201C601.536 20.471 600.332 19.6662 599.317 18.6516C598.303 17.6371 597.498 16.4326 596.949 15.107C596.4 13.7814 596.117 12.3607 596.117 10.9259L607.043 10.9259L607.043 21.8517Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#path-17-inside-3_0_1)"
      />
      <mask id="path-18-inside-4_0_1" fill="white">
        <path d="M9.76261 21.8517C11.1974 21.8517 12.6182 21.5691 13.9438 21.02C15.2693 20.4709 16.4738 19.6661 17.4884 18.6516C18.5029 17.637 19.3077 16.4325 19.8568 15.107C20.4059 13.7814 20.6885 12.3606 20.6885 10.9258L9.76261 10.9258L9.76261 21.8517Z" />
      </mask>
      <path
        d="M9.76261 21.8517C11.1974 21.8517 12.6182 21.5691 13.9438 21.02C15.2693 20.4709 16.4738 19.6661 17.4884 18.6516C18.5029 17.637 19.3077 16.4325 19.8568 15.107C20.4059 13.7814 20.6885 12.3606 20.6885 10.9258L9.76261 10.9258L9.76261 21.8517Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#path-18-inside-4_0_1)"
      />
      <line
        x1="606.132"
        y1="241.779"
        x2="556.055"
        y2="241.779"
        stroke="white"
      />
      <line
        x1="556.555"
        y1="104.706"
        x2="556.555"
        y2="241.279"
        stroke="white"
      />
      <line
        x1="556.838"
        y1="104.987"
        x2="606.132"
        y2="104.987"
        stroke="white"
      />
      <line
        x1="606.133"
        y1="209.038"
        x2="579.677"
        y2="209.038"
        stroke="white"
      />
      <line
        x1="580.177"
        y1="135.575"
        x2="580.177"
        y2="208.539"
        stroke="white"
      />
      <line
        x1="579.677"
        y1="135.904"
        x2="605.719"
        y2="135.904"
        stroke="white"
      />
      <line
        y1="-0.5"
        x2="50.0769"
        y2="-0.5"
        transform="matrix(1 -4.37114e-08 -4.37114e-08 -1 10.6729 244.921)"
        stroke="white"
      />
      <line
        y1="-0.5"
        x2="136.573"
        y2="-0.5"
        transform="matrix(4.37114e-08 1 1 -4.37114e-08 60.75 108.348)"
        stroke="white"
      />
      <line
        y1="-0.5"
        x2="49.2945"
        y2="-0.5"
        transform="matrix(-1 4.37114e-08 4.37114e-08 1 59.9678 109.129)"
        stroke="white"
      />
      <line
        y1="-0.5"
        x2="26.4557"
        y2="-0.5"
        transform="matrix(1 -4.37114e-08 -4.37114e-08 -1 10.6738 212.181)"
        stroke="white"
      />
      <line
        y1="-0.5"
        x2="72.9638"
        y2="-0.5"
        transform="matrix(4.37114e-08 1 1 -4.37114e-08 37.1299 139.217)"
        stroke="white"
      />
      <line
        y1="-0.5"
        x2="26.0423"
        y2="-0.5"
        transform="matrix(-1 4.37114e-08 4.37114e-08 1 37.1299 140.046)"
        stroke="white"
      />
    </svg>
    <div className="absolute -left-4 rounded-full shadow-shadow dark:shadow-shadow-dark drop-shadow-md w-12 h-12 bg-bg-secondary dark:bg-bg-secondary-dark flex flex-col items-center justify-center">
      <ClavaImage image={team1} width={36} />
    </div>
    <div className="absolute -right-4 rounded-full shadow-shadow dark:shadow-shadow-dark drop-shadow-md w-12 h-12 bg-bg-secondary dark:bg-bg-secondary-dark flex flex-col items-center justify-center">
      <ClavaImage image={team2} width={36} />
    </div>
  </div>
);
export default Field;
