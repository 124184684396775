import React, { useContext, useEffect, useMemo, useState } from "react";

import {
  actualMatchMinute,
  formatDate,
  fullMatchLengthMinutesRaw,
  MATCH_STATUS,
  matchLengthMinutesRaw,
  matchStatusDate,
} from "../../../../config/utils";
import { ClavaContext } from "../../../../config/contexts";

const MatchStatusDisplay: React.FC<{
  startDate: number;
  endTime: number;
  hideLive?: boolean;
  hideMinute?: boolean;
  fullDate?: boolean;
  sectionAmount: number;
  sectionDuration: number;
  pauseDuration: number;
}> = React.memo(
  ({
    startDate,
    sectionAmount,
    sectionDuration,
    pauseDuration,
    fullDate,
    hideLive,
    endTime,
    hideMinute,
  }) => {
    const { l } = useContext(ClavaContext);
    const matchLength = useMemo(
      () => matchLengthMinutesRaw(sectionAmount, sectionDuration),
      [sectionAmount, sectionDuration]
    );
    const [status, setStatus] = useState<MATCH_STATUS>(
      matchStatusDate(
        startDate,
        fullMatchLengthMinutesRaw(
          sectionAmount,
          sectionDuration,
          pauseDuration
        ),
        endTime
      )
    );
    const [doAnim, setDoAnim] = useState(false);
    useEffect(() => {
      setDoAnim(true);
      setTimeout(() => {
        setDoAnim(false);
      }, 1000);
    }, [status]);
    useEffect(() => {
      const interval = setInterval(() => {
        setStatus(
          matchStatusDate(
            startDate,
            fullMatchLengthMinutesRaw(
              sectionAmount,
              sectionDuration,
              pauseDuration
            ),
            endTime
          )
        );
        return () => {
          clearInterval(interval);
        };
      }, 10000);
    }, [endTime, pauseDuration, sectionAmount, sectionDuration, startDate]);
    if (typeof status === "number" || status === "ot")
      return (
        <>
          {!hideLive && <strong className="text-red">LIVE</strong>}
          {!hideMinute && (
            <div className={doAnim ? " bumb" : ""}>
              <strong className="text-red">
                {typeof status === "number"
                  ? `${actualMatchMinute(
                      status,
                      sectionAmount,
                      sectionDuration,
                      pauseDuration
                    ).toString(10)}'`
                  : `${matchLength}+`}
              </strong>
            </div>
          )}
        </>
      );
    if (status === "past") return <span>FT</span>;
    return (
      <>
        {!!fullDate && (
          <span>
            {formatDate(new Date(startDate), l, false, false, true, true)}
          </span>
        )}
        <span className="font-semibold">
          {formatDate(new Date(startDate), l, false, true)}
        </span>
      </>
    );
  },
  (prevProps, nextProps) => prevProps.startDate === nextProps.startDate
);

export default MatchStatusDisplay;
