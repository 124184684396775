import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
} from "@fortawesome/pro-regular-svg-icons";
import { ChangeEvent } from "../../../../client/api";

const ChangeEventView: React.FC<{
  change1?: ChangeEvent;
  change2?: ChangeEvent;
}> = ({ change1, change2 }) => (
  <div className="flex flex-row items-stretch my-2">
    <div className="min-w-12 max-w-12 rounded-l-lg bg-light-gray dark:bg-light-gray-dark px-2 flex flex-row items-center justify-center">
      <span>{change1 ? `${change1.minute}'` : ""}</span>
    </div>
    <div className="bg-bg dark:bg-bg-dark flex flex-col items-start justify-between py-2 px-2">
      <FontAwesomeIcon
        icon={faArrowAltCircleDown}
        className={`w-4 h-4 ${change1 ? "text-red" : "text-transparent"}`}
      />
      <FontAwesomeIcon
        icon={faArrowAltCircleUp}
        className={`w-4 h-4 ${change1 ? "text-green" : "text-transparent"}`}
      />
    </div>
    <div className="flex-1 bg-bg dark:bg-bg-dark flex flex-col items-start justify-between py-2">
      <span className="text-sm">
        {change1
          ? `${change1.player.familyName} ${change1.player.givenName.slice(
              0,
              1
            )}`
          : ""}
      </span>
      <span className="text-sm">
        {change1
          ? `${change1.playerIn.familyName} ${change1.playerIn.givenName.slice(
              0,
              1
            )}`
          : ""}
      </span>
    </div>
    <div className="flex-1 bg-bg dark:bg-bg-dark flex flex-col items-end justify-between py-2">
      <span className="text-right text-sm">
        {change2
          ? `${change2.player.familyName} ${change2.player.givenName.slice(
              0,
              1
            )}`
          : ""}
      </span>
      <span className="text-right text-sm">
        {change2
          ? `${change2.playerIn.familyName} ${change2.playerIn.givenName.slice(
              0,
              1
            )}`
          : ""}
      </span>
    </div>
    <div className="bg-bg dark:bg-bg-dark flex flex-col items-start justify-between py-2 px-2">
      <FontAwesomeIcon
        icon={faArrowAltCircleDown}
        className={`w-4 h-4 ${change2 ? "text-red" : "text-transparent"}`}
      />
      <FontAwesomeIcon
        icon={faArrowAltCircleUp}
        className={`w-4 h-4 ${change2 ? "text-green" : "text-transparent"}`}
      />
    </div>
    <div className="min-w-12 max-w-12 rounded-r-lg bg-light-gray dark:bg-light-gray-dark px-2 flex flex-row items-center justify-center">
      <span>{change2 ? `${change2.minute}'` : ""}</span>
    </div>
  </div>
);

export default ChangeEventView;
