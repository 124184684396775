import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import {
  deleteAccount,
  logout,
  refreshToken,
} from "../../../store/actions/userActions";
import { performAction } from "../../../store/actions/all";
import client from "../../../client";
import EventsSocket from "../../../client/Websockets/events";
import { AS_ENDPOINT } from "../../../config/constants";

const mapper = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  logout: () => {
    logout(dispatch);
  },
  deleteAccount: () => {
    performAction({ f: deleteAccount, p: [dispatch] });
  },
  setEndpoint: (endpoint: string) => {
    client().setEndpoint(endpoint);
    EventsSocket.setEndpoint(endpoint);
    localStorage.setItem(AS_ENDPOINT, endpoint);
    refreshToken(dispatch);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  },
});

const props = (state: RootState) => ({
  changeUsernameStatus: state.user.userStatus,
});

export const connector = connect(props, mapper);
