import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import React from "react";
import { RootState } from "../../../store";
import { IDType } from "../../../config/types";
import { performAction } from "../../../store/actions/all";
import {
  fetchMatchDays,
  fetchMatchDaysOfLeague,
} from "../../../store/actions/matchActions";
import { SportEnum } from "../../../client/api";

const mapper = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  getToday: (
    id: IDType,
    type: "aoi" | "league",
    sports: SportEnum,
    date?: Date
  ) => {
    if (type === "aoi")
      performAction({
        f: fetchMatchDays,
        p: [dispatch, id, date || new Date(), "today", sports],
      });
    else
      performAction({
        f: fetchMatchDaysOfLeague,
        p: [dispatch, id, date || new Date(), "today", sports],
      });
  },
  getBigger: (
    id: IDType,
    date: Date,
    type: "aoi" | "league",
    sports: SportEnum
  ) => {
    if (type === "aoi")
      performAction({
        f: fetchMatchDays,
        p: [dispatch, id, date, "bigger", sports],
      });
    else
      performAction({
        f: fetchMatchDaysOfLeague,
        p: [dispatch, id, date, "bigger", sports],
      });
  },
  getSmaller: (
    id: IDType,
    date: Date,
    type: "aoi" | "league",
    sports: SportEnum
  ) => {
    if (type === "aoi")
      performAction({
        f: fetchMatchDays,
        p: [dispatch, id, date, "smaller", sports],
      });
    else
      performAction({
        f: fetchMatchDaysOfLeague,
        p: [dispatch, id, date, "smaller", sports],
      });
  },
  getMonth: (
    id: IDType,
    date: Date,
    type: "aoi" | "league",
    sports: SportEnum
  ) => {
    if (type === "aoi")
      performAction({
        f: fetchMatchDays,
        p: [dispatch, id, date, "month", sports],
      });
    else
      performAction({
        f: fetchMatchDaysOfLeague,
        p: [dispatch, id, date, "month", sports],
      });
  },
});

const props = (
  state: RootState,
  prevProps: {
    selectedDate: Date | undefined;
    setSelectedDate: (date: Date | undefined) => void;
    type: "aoi" | "league";
    id: IDType;
    shouldScroll: React.MutableRefObject<boolean>;
    disabled: boolean;
  }
) => {
  let matchDays: Date[] = [];
  if (prevProps.type === "aoi") {
    return {
      matchDays: state.match.matchDays,
      sports: state.user.sportsType,
      ...prevProps,
    };
  }
  const filtered = state.match.leagueMatchDays.filter(
    (m) => m.id === prevProps.id
  );
  if (filtered.length) matchDays = filtered[0].response;

  return {
    matchDays: [...matchDays].sort((a, b) => a.getTime() - b.getTime()),
    sports: state.user.sportsType,
    ...prevProps,
  };
};

export const connector = connect(props, mapper);
// reload
