import {
  Ad,
  AreaOfInterest,
  Blog,
  Bulletin,
  File,
  Group,
  Language,
  League,
  LeagueCategory,
  Match,
  MatchLeague,
  MatchTournamentGroup,
  MatchTournamentStage,
  Player,
  PlayerListElement,
  PlayerPosition,
  PlayerStatistic,
  PlayerStatisticDetail,
  PlayerStatisticsListNew,
  Post,
  SearchResult,
  SportEnum,
  Squad,
  SquadList,
  Standing,
  Team,
  TeamListElement,
  Tournament,
  TournamentGroup,
  TournamentGroupStanding,
  TournamentMinimal,
  TournamentSecret,
  TournamentStage,
  TournamentTeam,
  Transfer,
  User,
} from "../../client/api";
import { TranslatorKeys } from "../../config/translator";
import {
  AllStanding,
  Favorite,
  IDType,
  LeagueMatchSmallElement,
  LineupExtended,
  MatchSmallElement,
  Notification,
  SearchQuery,
} from "../../config/types";

export interface UserState {
  readonly value: User | null;
  readonly values: Group[];
  readonly areaOfInterest: AreaOfInterest | null;
  readonly favorites: Favorite[];
  readonly language: Language | null;
  readonly error: TranslatorKeys | null;
  readonly status: "idle" | "loading" | "failed";
  readonly registerStatus: FormResponses;
  readonly favoriteStatus: "idle" | "loading" | "failed";
  readonly confirmMailStatus: FormResponses;
  readonly confirmTelStatus: FormResponses;
  readonly loginStatus: FormResponses;
  readonly insiderStatus: FormResponses;
  readonly userStatus: FormResponses;
  readonly thumb: File | null;
  readonly versionOk: boolean;
  readonly sportsType: SportEnum;
}

export interface AoiState {
  readonly value: AreaOfInterest[];
  readonly error: TranslatorKeys | null;
  readonly status: "idle" | "loading" | "failed";
}
export interface RouteState {
  readonly settings: boolean;
  readonly search: boolean;
  readonly logs: boolean;
  readonly status: "idle" | "loading" | "failed";
  readonly error: TranslatorKeys | null;
  readonly shareContent: undefined | Match | Standing[] | LineupExtended;
}
export interface SearchState {
  readonly result: SearchResult | undefined;
  readonly prevQueries: SearchQuery[];
  readonly status: "idle" | "loading" | "failed";
  readonly error: TranslatorKeys | null;
}
export interface LanguageState {
  readonly value: Language[];
  readonly error: TranslatorKeys | null;
  readonly status: "idle" | "loading" | "failed";
}

export interface LeagueState {
  readonly value: League[];
  readonly categories: LeagueCategory[];
  readonly error: TranslatorKeys | null;
  readonly status: "idle" | "loading" | "failed";
}

export interface NewsState {
  readonly news: Blog[];
  readonly transfers: Transfer[];
  readonly bulletins: Bulletin[];
  readonly feed: Post[];
  readonly statusFeed: "idle" | "loading" | "failed";
  readonly statusNews: "idle" | "loading" | "failed";
  readonly statusTransfers: "idle" | "loading" | "failed";
  readonly statusBulletins: "idle" | "loading" | "failed";
  readonly statusVideos: "idle" | "loading" | "failed";
  readonly error: TranslatorKeys | null;
}
export interface TeamState {
  readonly value: Team[];
  readonly searchValue: TeamListElement[];
  readonly ofLeague: ValueStore<TeamListElement[]>[];
  readonly player: Player | undefined;
  readonly players: Record<string, ValueStore<PlayerListElement[]>>;
  readonly statistics: ValueStore<PlayerStatistic[]>[];
  readonly statisticsDetail: ValueStore<PlayerStatisticDetail>[];
  readonly error: TranslatorKeys | null;
  readonly squad: Record<string, ValueStore<Squad[]>>;
  readonly positions: PlayerPosition[];
  readonly status: "idle" | "loading" | "failed";
  readonly squadStatus: "idle" | "loading" | "failed";
}
export interface ServerState {
  readonly status: boolean;
  readonly networkStatus: boolean;
  readonly error: TranslatorKeys | null;
  readonly notifications: Notification[];
}

export interface MatchState {
  readonly matchDays: Date[];
  readonly leagueMatchDays: ValueStore<Date[]>[];
  readonly matches: MatchLeague[];
  readonly matchElements: ValueStore<MatchSmallElement[]> | undefined;
  readonly leagueMatches: ValueStore<LeagueMatchSmallElement[]> | undefined;
  readonly error: TranslatorKeys | null;
  readonly status: "idle" | "loading" | "failed";
  readonly statusMatchDays: "idle" | "loading" | "failed";
}

export interface StandingState {
  readonly value: ValueStore<AllStanding>[];
  readonly error: TranslatorKeys | null;
  readonly status: "idle" | "loading" | "failed";
}

export interface AdState {
  readonly value: ValueStore<Ad[]>[];
  readonly error: TranslatorKeys | null;
  readonly status: "idle" | "loading" | "failed";
}

export interface CupState {
  readonly cup: Tournament | null;
  readonly cups: ValueStore<TournamentMinimal[]> | null;
  readonly status: "idle" | "loading" | "failed";
  readonly error: TranslatorKeys | null;
  readonly stages: TournamentStage[];
  readonly stagesStatus: "idle" | "loading" | "failed";
  readonly stagesError: TranslatorKeys | null;
  readonly groups: TournamentGroup[];
  readonly groupsStatus: "idle" | "loading" | "failed";
  readonly groupsError: TranslatorKeys | null;
  readonly match: MatchTournamentGroup | MatchTournamentStage | null;
  readonly matchesSmall: ValueStore<MatchSmallElement[]>[];
  readonly matchesStatus: "idle" | "loading" | "failed";
  readonly matchesError: TranslatorKeys | null;
  readonly squad: ValueStore<SquadList>[];
  readonly squadError: TranslatorKeys | null;
  readonly squadStatus: "idle" | "loading" | "failed";
  readonly standings: ValueStore<TournamentGroupStanding[]>[];
  readonly standingsStatus: "idle" | "loading" | "failed";
  readonly standingsError: TranslatorKeys | null;
  readonly topScorer: ValueStore<PlayerStatisticsListNew>[];
  readonly topScorerStatus: "idle" | "loading" | "failed";
  readonly topScorerError: TranslatorKeys | null;
  readonly secrets: ValueStore<TournamentSecret>[];
  readonly secretsStatus: "idle" | "loading" | "failed";
  readonly secretsError: TranslatorKeys | null;
  readonly team: TournamentTeam[];
  readonly teamStatus: "idle" | "loading" | "failed";
  readonly teamError: TranslatorKeys | null;
  readonly eventsStatus: "idle" | "loading" | "failed";
  readonly eventsError: TranslatorKeys | null;
}
export type ValueStore<T> = {
  id: IDType;
  date?: number;
  response: T;
  fetchDate: Date;
};

export type FormResponses =
  | "ok"
  | "confirmSuccess"
  | (TranslatorKeys &
      (
        | "ok"
        | "pwNotSame"
        | "pwNotValid"
        | "pwWrong"
        | "notRegistered"
        | "mailInvalid"
        | "mailGiven"
        | "telGiven"
        | "telInvalid"
        | "usernameGiven"
        | "failed"
      ));
export const SEARCH_STORAGE_KEY = "search_queries_v2";
