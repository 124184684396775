/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum JerseyModeEnum {
    VERTICAL_STRIPE = 'VERTICAL_STRIPE',
    HORIZONTAL_STRIPE = 'HORIZONTAL_STRIPE',
    DUO = 'DUO',
    MONO = 'MONO',
    CHECKERED = 'CHECKERED',
    SLEEVE = 'SLEEVE',
}
