import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { Language } from "../../../client/api";
import { performAction } from "../../../store/actions/all";
import { changeLanguage } from "../../../store/actions/userActions";
import { fetchLanguages } from "../../../store/actions/languageActions";

const mapper = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  changeLang: (lang: Language) => {
    performAction({ f: changeLanguage, p: [dispatch, lang] });
  },
  getLanguages: () => {
    performAction({ f: fetchLanguages, p: [dispatch] });
  },
});

const props = (state: RootState) => ({
  user: state.user.value,
  languages: state.languages.value,
  status: state.languages.status,
});

export const connector = connect(props, mapper);
