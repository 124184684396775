/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EventTypeEnum {
    EVENT = 'EVENT',
    CARD = 'CARD',
    GOAL = 'GOAL',
    CHANCE = 'CHANCE',
    CHANGE = 'CHANGE',
}
