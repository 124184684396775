import React, { MutableRefObject, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faHockeyPuck } from "@fortawesome/pro-regular-svg-icons";
// eslint-disable-next-line import/no-extraneous-dependencies
import { faFutbol } from "@fortawesome/pro-duotone-svg-icons";
import {
  CardTypeEnum,
  EventType,
  EventTypeEnum,
  GoalTypeEnum,
  SportEnum,
} from "../../../../client/api";
import { ClavaContext } from "../../../../config/contexts";
import { IDType } from "../../../../config/types";
import { showTranslated, translate } from "../../../../config/translator";

const MatchEventSide: React.FC<{ event: EventType }> = ({ event }) => {
  const { l } = useContext(ClavaContext);

  return (
    <>
      <span className="text-green font-bold">{`${
        event.minute ?? translate("noMinutesShort", l)
      }'`}</span>
      <span className="font-bold">
        {event.player
          ? `${event.player.familyName} ${event.player.givenName.slice(0, 1)}.`
          : translate(event.type === EventTypeEnum.GOAL ? "goal" : "card", l)}
      </span>
      {event.type === EventTypeEnum.GOAL &&
        (event.assist ? (
          <span className="text-sm opacity-60">
            {`${event.assist.familyName} ${event.assist.givenName.slice(
              0,
              1
            )}.`}
            {event.goalType && (
              <>
                <br />
                {showTranslated(event.goalType.translation, l)}
              </>
            )}
          </span>
        ) : event.goalType ? (
          <span className="text-sm opacity-60">
            {showTranslated(event.goalType.translation, l)}
          </span>
        ) : null)}
      {event.type === EventTypeEnum.CHANCE && !!event.chanceType && (
        <span className="text-sm opacity-60">
          {showTranslated(event.chanceType.translation, l)}
        </span>
      )}
    </>
  );
};

const MatchEvent: React.FC<{
  event: EventType;
  team1Id: IDType;
  standing1: MutableRefObject<number>;
  sports: SportEnum;
  team2Id: IDType;
  standing2: MutableRefObject<number>;
}> = ({ event, team2Id, sports, team1Id, standing1, standing2 }) => (
  <div className="flex flex-row items-center justify-center z-20 my-2">
    <div className="flex-1 flex flex-col items-end justify-center text-right">
      {event.teamId === team1Id && <MatchEventSide event={event} />}
    </div>
    <div className="flex flex-col items-center justify-center mx-8 z-20">
      <div className="w-10 h-10 flex flex-col items-center justify-center border border-light-gray dark:border-light-gray-dark rounded-full bg-bg-secondary dark:bg-bg-secondary-dark z-20">
        {event.type === EventTypeEnum.GOAL ? (
          <FontAwesomeIcon
            icon={sports === SportEnum.SOCCER ? faFutbol : faHockeyPuck}
            className={`w-6 h-6 text-primary ${
              event.goalType && event.goalType.key === GoalTypeEnum.OWN_GOAL
                ? "!text-red"
                : ""
            }`}
          />
        ) : event.type === EventTypeEnum.CARD ? (
          <svg
            className="w-6 h-6 text-primary"
            viewBox="0 0 20 30"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.28887 12.0511C6.2574 13.6304 6.27466 15.2106 6.28481 16.7898C6.28684 17.0484 6.21069 17.2537 6.05229 17.4663C5.80658 17.7948 5.53852 18.1289 5.40043 18.497C5.23595 18.9406 4.94759 19.0262 4.49271 18.9967C3.73627 18.947 2.96256 19.0888 2.22237 18.8955C0.869915 18.5421 0.0210777 17.5629 0.0149855 16.2892C-0.00329087 11.9802 -0.00735229 7.67038 0.0170163 3.36144C0.0251391 1.77021 1.33901 0.645583 3.10675 0.641901C6.10103 0.635459 9.09633 0.634539 12.0906 0.641901C13.8259 0.646503 15.1022 1.72512 15.1458 3.28322C15.1905 4.90758 15.158 6.53378 15.158 8.15999C15.158 8.19956 15.1326 8.23914 15.1184 8.28147C14.9153 8.28791 14.8179 8.14066 14.6828 8.06335C12.908 7.05192 11.0529 6.92216 9.19787 7.7937C7.32656 8.67353 6.32644 10.1212 6.28887 12.0511Z"
              fill="#F7F9FA"
              transform={
                event.cardType.key === CardTypeEnum.YELLOW_RED
                  ? "translate(5 5)"
                  : "translate(2.5 2.5)"
              }
            />
            <path
              d="M17.8867 16.4163C17.0743 17.9675 16.2706 19.5231 15.4562 21.073C15.3625 21.2514 15.3204 21.4258 15.3224 21.6234C15.3284 22.2861 15.3278 22.9488 15.3238 23.6115C15.3211 24.1612 15.1071 24.362 14.5327 24.3633C13.7979 24.3639 13.0624 24.3633 12.3268 24.3633C11.5913 24.3633 10.8558 24.3652 10.1209 24.3627C9.61009 24.3614 9.34864 24.1465 9.38876 23.6679C9.48104 22.5639 9.20689 21.5817 8.68133 20.5829C7.83614 18.9772 8.09224 17.3978 9.22962 15.9641C9.33795 15.8268 9.38408 15.6966 9.38341 15.5272C9.37806 14.3302 9.41284 13.1318 9.37205 11.9361C9.32725 10.5934 10.5469 9.4637 12.03 9.65872C12.9955 9.78573 13.9149 10.6492 13.951 11.5903C13.9885 12.5506 13.9811 13.5135 13.9697 14.4758C13.9657 14.8331 14.0607 15.1192 14.3509 15.3514C14.5294 15.4945 14.6772 15.6728 14.8517 15.8217C15.1091 16.0411 15.4963 16.0289 15.7263 15.8075C15.9429 15.5984 15.9938 15.1821 15.7383 14.9999C15.2114 14.6246 15.2001 14.1262 15.2215 13.5822C15.2435 13.0054 15.2255 12.4275 15.2268 11.8501C15.2275 11.5672 15.3331 11.4998 15.6106 11.6115C15.7711 11.6756 15.8787 11.7962 15.9563 11.945C16.6103 13.2024 17.2635 14.461 17.9208 15.7171C18.0492 15.9615 18.0091 16.1822 17.8867 16.4163Z"
              fill="currentColor"
              transform={
                event.cardType.key === CardTypeEnum.YELLOW_RED
                  ? "translate(5 5)"
                  : "translate(2.5 2.5)"
              }
            />
            <path
              d="M15.158 8.15999C15.158 8.19956 15.1326 8.23914 15.1184 8.28147C14.9153 8.28791 14.8179 8.14066 14.6828 8.06335C12.908 7.05192 11.0529 6.92216 9.19787 7.7937C7.32656 8.67353 6.32644 10.1212 6.28887 12.0511C6.2574 13.6304 6.27466 15.2106 6.28481 16.7898C6.28684 17.0484 6.21069 17.2537 6.05229 17.4663C5.80658 17.7948 5.53852 18.1289 5.40043 18.497C5.23595 18.9406 4.94759 19.0262 4.49271 18.9967C3.73627 18.947 2.96256 19.0888 2.22237 18.8955C0.869915 18.5421 0.0210777 17.5629 0.0149855 16.2892C-0.00329087 11.9802 -0.00735229 7.67038 0.0170163 3.36144C0.0251391 1.77021 1.33901 0.645583 3.10675 0.641901C6.10103 0.635459 9.09633 0.634539 12.0906 0.641901C13.8259 0.646503 15.1022 1.72512 15.1458 3.28322C15.1905 4.90758 15.158 6.53378 15.158 8.15999Z"
              fill={
                event.cardType.key === CardTypeEnum.RED ? "#FF7272" : "#FED636"
              }
              transform={
                event.cardType.key !== CardTypeEnum.YELLOW_RED
                  ? "translate(2.5 2.5)"
                  : undefined
              }
            />
            {event.cardType.key === CardTypeEnum.YELLOW_RED && (
              <path
                d="M15.158 8.15999C15.158 8.19956 15.1326 8.23914 15.1184 8.28147C14.9153 8.28791 14.8179 8.14066 14.6828 8.06335C12.908 7.05192 11.0529 6.92216 9.19787 7.7937C7.32656 8.67353 6.32644 10.1212 6.28887 12.0511C6.2574 13.6304 6.27466 15.2106 6.28481 16.7898C6.28684 17.0484 6.21069 17.2537 6.05229 17.4663C5.80658 17.7948 5.53852 18.1289 5.40043 18.497C5.23595 18.9406 4.94759 19.0262 4.49271 18.9967C3.73627 18.947 2.96256 19.0888 2.22237 18.8955C0.869915 18.5421 0.0210777 17.5629 0.0149855 16.2892C-0.00329087 11.9802 -0.00735229 7.67038 0.0170163 3.36144C0.0251391 1.77021 1.33901 0.645583 3.10675 0.641901C6.10103 0.635459 9.09633 0.634539 12.0906 0.641901C13.8259 0.646503 15.1022 1.72512 15.1458 3.28322C15.1905 4.90758 15.158 6.53378 15.158 8.15999Z"
                fill="#FF7272"
                transform="translate(5 5)"
              />
            )}
          </svg>
        ) : event.type === EventTypeEnum.CHANCE ? (
          <FontAwesomeIcon icon={faBolt} className="w-6 h-6 text-primary" />
        ) : null}
      </div>
      {event.type === EventTypeEnum.GOAL && (
        <span className="font-bold bg-bg-secondary dark:bg-bg-secondary-dark z-20">
          {event.teamId === team1Id
            ? `${++standing1.current} - ${standing2.current}`
            : `${standing1.current} - ${++standing2.current}`}
        </span>
      )}
    </div>
    <div className="flex-1 flex flex-col items-start justify-center text-left">
      {event.teamId === team2Id && <MatchEventSide event={event} />}
    </div>
  </div>
);

export default MatchEvent;
// relo  ad
