import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";
import register from "./registerServiceWorker";
import reportWebVitals from "./reportWebVitals";

const rootNode = document.getElementById("app");
Sentry.init({
  dsn: "https://d6d2579347d046e4969560cfa7a8417d@sentry.kivi.bz.it/41",
  environment:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "development"
      : "production",
  tracesSampleRate: 0.01,
});
if (rootNode) {
  createRoot(rootNode).render(<App />);
}

register();
reportWebVitals();
