import { Dispatch } from "redux";
import client from "../../client";
import { IDType } from "../../config/types";
import { defaultGet } from "./all";
import { LeagueActions, LeagueActionTypes } from "./types";
import { SportEnum } from "../../client/api";

export function fetchLeagues(
  dispatch: Dispatch<LeagueActions>,
  aoiID: IDType,
  sports: SportEnum
) {
  defaultGet(
    dispatch,
    LeagueActionTypes.FETCH_SUCCESS,
    LeagueActionTypes.FETCH_ERROR,
    LeagueActionTypes.FETCH_LEAGUES,
    client().getLeaguesOfAoi,
    false,
    { id: aoiID },
    aoiID,
    sports
  );
}
export function fetchCategories(dispatch: Dispatch<LeagueActions>) {
  defaultGet(
    dispatch,
    LeagueActionTypes.FETCH_CATEGORIES_SUCCESS,
    LeagueActionTypes.FETCH_ERROR,
    LeagueActionTypes.FETCH_LEAGUES,
    client().getLeagueCategories,
    false,
    false
  );
}
