import { mapToMatchSmall, sameDay } from "./utils";
import { ValueStore } from "../store/constants";
import { MatchLeague, MatchType } from "../client/api";
import { IDType, LeagueMatchSmallElement, MatchSmallElement } from "./types";

export function notFoundButInBetween(dates: Date[], add: Date): boolean {
  let found = false;
  let min = Infinity;
  let max = 0;
  dates.forEach((day) => {
    if (sameDay(day, add)) {
      found = true;
    }
    if (day.getTime() > max) {
      max = day.getTime();
    }
    if (day.getTime() < min) {
      min = day.getTime();
    }
  });
  return !found && add.getTime() < max && add.getTime() > min;
}

export function addMatchDays(
  matchDays: Date[],
  addDate: string,
  removeDate: string | null
) {
  const isSameDay = addDate === removeDate;
  if (isSameDay) {
    return matchDays;
  }
  const add = new Date(addDate);
  if (notFoundButInBetween(matchDays, add)) {
    return matchDays.concat([add]).sort((a, b) => a.getTime() - b.getTime());
  }
  return matchDays;
}

export function addMatchDaysLeague(
  leagueId: IDType,
  leagueMatchDays: ValueStore<Date[]>[],
  addDate: string,
  removeDate: string | null
) {
  const isSameDay = addDate === removeDate;
  if (isSameDay) {
    return leagueMatchDays;
  }
  const add = new Date(addDate);
  for (let i = 0; i < leagueMatchDays.length; i++) {
    if (leagueMatchDays[i].id === leagueId) {
      if (notFoundButInBetween(leagueMatchDays[i].response, add)) {
        return leagueMatchDays.map((lmd) =>
          lmd.id === leagueId
            ? {
                ...lmd,
                response: lmd.response
                  .concat([add])
                  .sort((a, b) => a.getTime() - b.getTime()),
              }
            : lmd
        );
      }
    }
  }
  return leagueMatchDays;
}

export function redoMatchesHomeScreen(
  matches: ValueStore<LeagueMatchSmallElement[]> | undefined,
  match: MatchLeague,
  aoi: number,
  date: number
) {
  if (!matches) return;
  if (matches.id !== aoi || matches.date !== date) return;
  const d = matches.response;
  for (let leagueIdx = 0; leagueIdx < d.length; leagueIdx++) {
    if (d[leagueIdx].leagueTournamentId === match.league.id) {
      const lm = d[leagueIdx].matches;
      for (let mIdx = 0; mIdx < lm.length; mIdx++) {
        if (lm[mIdx].id === match.id) {
          matches.response[leagueIdx].matches[mIdx] = mapToMatchSmall(
            match,
            match.league
          );
          return;
        }
      }
      matches = {
        ...matches,
        response: matches.response.map((res, i) =>
          i === leagueIdx
            ? {
                ...res,
                matches: res.matches.concat([
                  mapToMatchSmall(match, match.league),
                ]),
              }
            : res
        ),
      };
      return;
    }
  }
  matches.response = matches.response.concat({
    leagueTournamentId: match.league.id,
    leagueTournamentType: MatchType.LEAGUE,
    leagueTournamentName: match.league.name,
    matches: [mapToMatchSmall(match, match.league)],
  });
}

export function redoMatchesLeagueScreen(
  matches: ValueStore<MatchSmallElement[]> | undefined,
  match: MatchLeague,
  date: number
) {
  if (!matches || matches.id !== match.league.id) {
    return;
  }
  const lm = matches.response;

  for (let mIdx = 0; mIdx < lm.length; mIdx++) {
    if (lm[mIdx].id === match.id) {
      if (date === matches.date) {
        matches.response[mIdx] = mapToMatchSmall(match, match.league);
      } else {
        matches.response = matches.response.filter((m) => m.id !== match.id);
      }
      return;
    }
  }
  matches.response = matches.response.concat([
    mapToMatchSmall(match, match.league),
  ]);
}

export function redoMatchesTeamScreen(
  matches: ValueStore<MatchSmallElement[]> | undefined,
  match: MatchLeague
) {
  if (
    !matches ||
    matches.id !== match.team1.id ||
    matches.id !== match.team2.id
  ) {
    return;
  }
  const lm = matches.response;

  for (let mIdx = 0; mIdx < lm.length; mIdx++) {
    if (lm[mIdx].id === match.id) {
      matches.response[mIdx] = mapToMatchSmall(match, match.league);
      return;
    }
  }
  matches.response = matches.response.concat([
    mapToMatchSmall(match, match.league),
  ]);
}
