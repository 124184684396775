import { connect } from "react-redux";
import { RootState } from "../../../../store";
import { MatchSmallElement } from "../../../../config/types";

const props = (
  state: RootState,
  prevProps: {
    type: "league" | "home" | "team";
    idx: number;
    idx2?: number;
  }
) => {
  let match: MatchSmallElement | undefined;
  if (
    prevProps.type === "home" &&
    typeof prevProps.idx2 === "number" &&
    !!state.match.leagueMatches
  ) {
    match =
      state.match.leagueMatches.response[prevProps.idx].matches[prevProps.idx2];
  }
  if (prevProps.type === "league" && state.match.matchElements) {
    match = state.match.matchElements.response[prevProps.idx];
  }
  return {
    match,
  };
};

export const connector = connect(props);
