import { Reducer } from "redux";
import { LeagueState } from "../constants";
import { LeagueActions, LeagueActionTypes } from "../actions/types";

const initialState: LeagueState = {
  value: [],
  error: null,
  status: "idle",
  categories: [],
};

const reducer: Reducer<LeagueState> = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: LeagueActions
) => {
  switch (action.type) {
    case LeagueActionTypes.RESET: {
      return {
        value: [],
        categories: [],
        error: null,
        status: "idle",
        teamStatistics: [],
        playerStatistics: [],
        playerStatisticsDetail: [],
        teamStatisticsDetail: [],
        tow: [],
      };
    }
    case LeagueActionTypes.FETCH_LEAGUES: {
      return { ...state, status: "loading" };
    }
    case LeagueActionTypes.FETCH_SUCCESS: {
      return {
        ...state,
        status: "idle",
        value: action.payload.response,
        error: null,
      };
    }

    case LeagueActionTypes.FETCH_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.payload,
      };
    }
    case LeagueActionTypes.FETCH_SUCCESS_SINGLE: {
      return {
        ...state,
        status: "idle",
        value: state.value.concat(action.payload),
        error: null,
      };
    }
    case LeagueActionTypes.FETCH_ERROR: {
      return { ...state, status: "failed", error: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as leagueReducer };
