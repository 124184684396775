import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { performAction } from "../../../store/actions/all";
import {
  initBaseData,
  login,
  refreshToken,
  register,
} from "../../../store/actions/userActions";
import { RootState, store } from "../../../store";
import { IDType } from "../../../config/types";
import { SportEnum, UserCreate } from "../../../client/api";
import { fetchLeagues } from "../../../store/actions/leagueActions";
import {
  AdActionTypes,
  LeagueActionTypes,
  MatchActionTypes,
  StandingActionTypes,
  TeamActionTypes,
} from "../../../store/actions/types";

const mapper = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  login: (email: string, password: string) => {
    performAction({ f: login, p: [dispatch, email, password] });
  },
  refreshToken: () => {
    performAction({ f: refreshToken, p: [dispatch] });
  },
  createUser: (userCreate: UserCreate) => {
    performAction({ f: register, p: [dispatch, userCreate] });
  },
  getLeagues: (aoi: IDType, sports: SportEnum) => {
    fetchLeagues(dispatch, aoi, sports);
  },
  initBaseDataUser: () => {
    initBaseData(dispatch, store);
  },

  resetStoredData: () => {
    dispatch({ type: MatchActionTypes.RESET });
    dispatch({ type: StandingActionTypes.RESET });
    dispatch({ type: LeagueActionTypes.RESET });
    dispatch({ type: TeamActionTypes.RESET });
    dispatch({ type: AdActionTypes.RESET });
  },
});

const props = (
  state: RootState,
  prevProps: { setTheme: (t: "dark" | "light") => void }
) => ({
  user: state.user.value,
  error: state.user.error,
  status: state.user.status,
  languageObject: state.user.language,
  aoi: state.user.areaOfInterest,
  sports: state.user.sportsType,
  ...prevProps,
});

export const connector = connect(props, mapper);
// reload
