import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleDown,
  faFutbol,
} from "@fortawesome/pro-regular-svg-icons";
import {
  CardEvent,
  CardTypeEnum,
  JerseyModeEnum,
  LineupPosition,
  Player,
  PlayerListElement,
  TeamListElement,
} from "../../../../../client/api";
import Jersey from "../../../Jersey";
import ClavaImage from "../../../ClavaImage";

export declare type LineupPositionPlayerField = {
  player: Player | PlayerListElement;
  team: TeamListElement;
  change: boolean;
  card: CardEvent | undefined;
  goal: number;
  position: LineupPosition;
};

const InFieldPlayer: React.FC<{
  p: LineupPositionPlayerField;
  rotated: boolean;
}> = ({ rotated, p }) => {
  const goals = useMemo(
    () =>
      p.goal !== 0 ? (
        <div className="absolute right-[-.65rem] top-[-.65rem]">
          <FontAwesomeIcon icon={faFutbol} className="w-3 h-3 text-black" />
        </div>
      ) : null,
    [p.goal]
  );
  const card = useMemo(
    () =>
      p.card ? (
        <div
          className={`w-2.5 h-3.5 text-primary absolute left-[-.55rem] bottom-[-.35rem] rounded-sm ${
            p.card.cardType.key === CardTypeEnum.RED
              ? "bg-red-card"
              : "bg-yellow-card"
          }`}
        >
          {p.card.cardType.key === CardTypeEnum.YELLOW_RED && (
            <div className="w-2.5 h-3.5 text-primary absolute left-[.15rem] bottom-[-.15rem] rounded-sm bg-red-card" />
          )}
        </div>
      ) : null,
    [p.card]
  );
  const change = useMemo(
    () =>
      p.change ? (
        <div className="absolute left-[-.65rem] top-[-.65rem]">
          <FontAwesomeIcon
            icon={faArrowAltCircleDown}
            className="w-3 h-3 text-red"
          />
        </div>
      ) : null,
    [p.change]
  );

  return (
    <div
      key={`lineup-pos-${p.player?.id}-${p.position.id}`}
      className="absolute"
      style={{
        left: rotated ? `auto` : `${p.position.y * 45 + 5}%`,
        right: rotated ? `${p.position.y * 45 + 5}%` : "auto",
        top: rotated ? `auto` : `${p.position.x * 98 + 1}%`,
        bottom: rotated ? `${p.position.x * 98 + 1}%` : "auto",
        transform: rotated ? `translateY(.4rem)` : `translateY(-1.1rem)`,
      }}
    >
      <div className="relative w-6 h-6 flex flex-col items-center justify-center">
        {p.player.photo ? (
          <div className="w-6 h-6 overflow-hidden rounded-full">
            <ClavaImage image={p.player.photo} width={24} />
          </div>
        ) : (
          <Jersey
            color1={
              p.team.jerseyColor1.length !== 0
                ? p.team.jerseyColor1
                : rotated
                ? "#000"
                : "#5AA0E1"
            }
            color2={
              p.team.jerseyColor2.length !== 0 ? p.team.jerseyColor2 : "#000"
            }
            mode={p.team.jerseyMode ? p.team.jerseyMode : JerseyModeEnum.MONO}
            size="100%"
          />
        )}
        <div className="absolute top-[100%]">
          <span className="text-xs text-nowrap font-bold">
            {`${p.player.familyName.slice(0, 8)} ${p.player.givenName.slice(
              0,
              1
            )}`}
          </span>
        </div>
        {card}
        {goals}
        {change}
      </div>
    </div>
  );
};

export default InFieldPlayer;
// re loa d
