import React, { useMemo } from "react";
import { useParams } from "react-router";
import LeagueMatches from "../../components/LeagueMatches";
import Leagues from "../../components/League";

const Home: React.FC = () => {
  const { leagueId } = useParams();
  const realLeagueId = useMemo(() => {
    if (!leagueId) return -1;
    const id = parseInt(leagueId, 10);
    return Number.isNaN(id) ? -1 : id;
  }, [leagueId]);
  return (
    <div className="flex flex-row">
      <Leagues leagueId={realLeagueId} />
      <LeagueMatches leagueId={realLeagueId} />
    </div>
  );
};

// relo ad
export default Home;
