import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "../../../store";
import { IDType } from "../../../config/types";
import { performAction } from "../../../store/actions/all";
import { fetchCategories } from "../../../store/actions/leagueActions";

const mapper = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  getCategories: () => {
    performAction({ f: fetchCategories, p: [dispatch] });
  },
});
const props = (state: RootState, prevProps: { leagueId?: IDType }) => ({
  leagues: state.leagues.value,
  ...prevProps,
  categories: state.leagues.categories,
  favLeagueIds: state.user.favorites
    .filter((f) => f.type === "league")
    .map((f) => f.id),
});

export const connector = connect(props, mapper);
// rel
