import React, { useContext } from "react";
import { ClavaContext } from "../../../config/contexts";
import { translate } from "../../../config/translator";

const Imprint: React.FC = () => {
  const { l } = useContext(ClavaContext);

  return (
    <div className="flex flex-col items-start justify-start py-6 px-6 lg:px-12">
      <h1 className="text-lg font-bold text-primary">
        {translate("imprint", l)}
      </h1>
      <p className="py-2">
        Clava Sports S.R.L
        <br />
        {translate("imprintRepresent", l)}: Angerer Ivan
        <br />
      </p>
      <p className="py-2">
        {translate("imprintStreet", l)}
        <br />
        {translate("imprintCity", l)}
        <br />
        {translate("imprintState", l)}
        <br />
        {translate("imprintRegister", l)}: BZ - 244206
        <br />
      </p>
      <p className="py-2">
        {translate("imprintPIva", l)}: 03247260213
        <br />
        PEC:{" "}
        <a className="underline" href="mailto:clava-sports@legalmail.it">
          clava-sports@legalmail.it
        </a>
        <br />
        E-Mail:{" "}
        <a className="underline" href="mailto:info@clava-sports.com">
          info@clava-sports.com
        </a>
        <br />
        Tel:{" "}
        <a className="underline" href="tel:+393780651939">
          +39 378 065 1939
        </a>
      </p>
      <h2 className="text-lg font-bold mt-4 text-primary">
        {translate("imprintRights", l)}
      </h2>
      <p className="py-2">
        @ Clava Sports S.R.L, {translate("imprintCity", l)}
      </p>
      <p className="py-2">
        {translate("imprintAllRightsReserved", l)}
        <br />
        {translate("imprintRights1", l)}
      </p>
      <h2 className="text-lg font-bold mt-4 text-primary">
        {translate("imprintDisclaimer", l)}
      </h2>
      <p className="py-2">{translate("imprintDisclaimer1", l)}</p>
      <p className="py-2">{translate("imprintDisclaimer2", l)}</p>
      <p className="py-2">{translate("imprintDisclaimer3", l)}</p>
    </div>
  );
};

export default Imprint;
// reloa  d
