import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ConnectedProps } from "react-redux";
import { DateTimeFormat } from "intl";
import { connector } from "./redux";
import { ClavaContext } from "../../../config/contexts";
import MatchDays from "../MatchDays";
import { showTranslated, translate } from "../../../config/translator";
import {
  dayToNumber,
  formatDate,
  intlLang,
  isFavorite,
  sameDay,
  shouldAdBePlaced,
  sortMatchesByTimeFast,
} from "../../../config/utils";
import Loading from "../Loading";
import LeagueMatchSection, { Section, SectionItem } from "./LeagueMatchSection";
import SportsTypePicker from "../SportsTypePicker";
import { MatchSmallElement } from "../../../config/types";

const reducer = (
  p: {
    date: Date;
    matches: (MatchSmallElement & { idx: number; idx2: number })[];
  }[],
  n: MatchSmallElement & { idx: number; idx2: number }
) => {
  const currentDate = new Date(n.startTime);
  let index = -1;
  p.forEach((v, i) => {
    if (sameDay(v.date, currentDate)) index = i;
  });
  if (index === -1)
    return p.concat([{ date: new Date(n.startTime), matches: [n] }]);
  p[index].matches.push(n);
  return p;
};

const emptyArr: {
  date: Date;
  matches: (MatchSmallElement & { idx: number; idx2: number })[];
}[] = [];

const LeagueMatches: React.FC<ConnectedProps<typeof connector>> = ({
  leagueMatches,
  matches,
  cups,
  leagueId,
  favorites,
  fetchCups,
  fetchLeagueMatchesOfDay,
  fetchLeagueMatchesOfDayAndLeague,
  league,
  sports,
}) => {
  const { l, aoi } = useContext(ClavaContext);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const shouldScroll = useRef<boolean>(true);
  const adPositions = useRef<string[]>([]);

  const selectDate = useCallback(
    (day: Date | undefined) => {
      setSelectedDate(day);
      if (day) {
        if (leagueId === -1) {
          fetchLeagueMatchesOfDay(aoi, day, sports);
        } else {
          fetchLeagueMatchesOfDayAndLeague(leagueId, day);
        }
        fetchCups(aoi, day, sports);
        adPositions.current = [];
      }
    },
    [
      sports,
      aoi,
      fetchCups,
      fetchLeagueMatchesOfDay,
      fetchLeagueMatchesOfDayAndLeague,
      leagueId,
    ]
  );
  useEffect(() => {
    if (!selectedDate) {
      selectDate(selectedDate);
      adPositions.current = [];
    }
  }, [
    aoi,
    fetchLeagueMatchesOfDay,
    fetchLeagueMatchesOfDayAndLeague,
    leagueId,
    selectDate,
    selectedDate,
  ]);
  const filtered = useMemo<Section[]>(() => {
    const favoriteMatches: SectionItem[] = [];
    const generateAds = adPositions.current.length === 0;
    const lm: Section[] = [];
    const favoriteLeagues: Section[] = [];
    const cupsSects: Section[] = [];
    if (cups.length !== 0) {
      cupsSects.push({
        title: {
          name: translate("cup", l),
          id: -1,
        },
        data: cups,
      });
    }
    let matchCounter = 0;
    if (leagueId === -1) {
      leagueMatches.forEach((leagueMatch, idx) => {
        const temp: Section = {
          title: {
            name: `${showTranslated(leagueMatch.leagueTournamentName, l)}`,
            id: leagueMatch.leagueTournamentId,
          },
          data: [],
        };
        leagueMatch.matches.forEach((match, idx2) => {
          const isValid =
            selectedDate &&
            dayToNumber(new Date(match.startTime)) ===
              dayToNumber(selectedDate);
          if (
            (isValid &&
              isFavorite(favorites, match.team1.id, "team", -1, -1)) ||
            isFavorite(favorites, match.team2.id, "team", -1, -1) ||
            isFavorite(favorites, match.id, "match", -1, -1)
          ) {
            favoriteMatches.push({ ...match, idx, idx2 });
          } else temp.data.push({ ...match, idx, idx2 });
        });
        temp.data = (temp.data as unknown as MatchSmallElement[]).sort(
          sortMatchesByTimeFast
        ) as SectionItem[];
        if (temp.data.length !== 0) {
          matchCounter += temp.data.length;
          const randIdx = Math.floor(Math.random() * temp.data.length);

          if (generateAds) {
            if (shouldAdBePlaced(matchCounter, adPositions.current.length)) {
              adPositions.current.push(`${lm.length}-${randIdx}`);
              matchCounter = 0;
            }
          }
          const adPos = adPositions.current.indexOf(`${lm.length}-${randIdx}`);
          if (adPos !== -1) {
            temp.data.splice(
              randIdx + 1,
              0,
              `${lm.length}-${randIdx}-${adPos === 0 ? "priority" : "normal"}`
            );
          }
          if (
            isFavorite(
              favorites,
              leagueMatch.leagueTournamentId,
              "league",
              -1,
              -1
            )
          )
            favoriteLeagues.push(temp);
          else lm.push(temp);
        }
      });

      return (
        favoriteMatches.length !== 0
          ? [
              {
                title: { name: translate("myLeagues", l), id: undefined },
                data: favoriteMatches,
              } as Section,
            ]
          : []
      )
        .concat(cupsSects)
        .concat(favoriteLeagues)
        .concat(lm);
    }
    return matches
      .map((m, idx) => ({ ...m, idx, idx2: -1 }))
      .sort((a, b) => a.startTime - b.startTime)
      .reduce(reducer, emptyArr)
      .map<Section>((elem) => ({
        title: {
          name: `${DateTimeFormat(intlLang(l), {
            weekday: "short",
          })
            .format(elem.date)
            .toUpperCase()
            .replace(".", "")}, ${formatDate(
            elem.date,
            l,
            false,
            false,
            true,
            false
          )}`,
          id: 0,
        },
        data: elem.matches,
      }));
  }, [cups, matches, leagueMatches, l, selectedDate, favorites, leagueId]);
  return (
    <div className="flex-1 flex flex-col items-stretch justify-start">
      <SportsTypePicker />
      <MatchDays
        type={leagueId === -1 ? "aoi" : "league"}
        id={leagueId === -1 ? aoi : leagueId}
        setSelectedDate={selectDate}
        selectedDate={selectedDate}
        shouldScroll={shouldScroll}
        disabled={false}
      />
      <div className="league-matches">
        {league && (
          <h3 className="text-center font-bold text-lg mt-4 text-primary">
            {showTranslated(league.name, l)}
          </h3>
        )}
        {filtered.length === 0 ? (
          <Loading small />
        ) : (
          filtered.map((section) => (
            <LeagueMatchSection
              section={section}
              type={leagueId === -1 ? "home" : "league"}
              key={`lm-section-${section.title.id}`}
            />
          ))
        )}
      </div>
    </div>
  );
};

// reload
export default connector(LeagueMatches);
