/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CardTypeEnum {
    YELLOW = 'YELLOW',
    YELLOW_RED = 'YELLOW_RED',
    RED = 'RED',
    BAN_2_MINUTES = 'BAN_2_MINUTES',
    BAN_4_MINUTES = 'BAN_4_MINUTES',
    BAN_5_MINUTES = 'BAN_5_MINUTES',
    BAN_MATCH = 'BAN_MATCH',
}
