import * as Sentry from "@sentry/react";
import { io, Socket } from "socket.io-client";
import { DisconnectDescription } from "socket.io-client/build/esm/socket";
import {
  BETA_ENDPOINT,
  BETA_SOCKET_ENDPOINT,
  DEV_ENDPOINT,
  DEV_SOCKET_ENDPOINT,
  PROD_SOCKET_ENDPOINT,
  STAG_ENDPOINT,
  STAG_SOCKET_ENDPOINT,
} from "../../config/constants";
import { store } from "../../store";
import { fetchMe } from "../../store/actions/userActions";
import { fetchByLeague } from "../../store/actions/standingActions";
import { getLineupOfMatch } from "../../store/actions/matchActions";
import { CupActionTypes, MatchActionTypes } from "../../store/actions/types";
import { errorLogging } from "../../config/utils";
import client, { StaticClient } from "../index";
import { addLog } from "../../store/middleware/logger";
import { MatchLocationEnum, MatchType, Task } from "../api";
import { IDType } from "../../config/types";
import { fetchStandings } from "../../store/actions/cupActions";

type LazyGoalMessage = {
  match_id: IDType;
  league_id: IDType;
  type: "lazy_goal";
};
type LineupSocketMessage = {
  match_id: IDType;
  team_id: IDType;
  type: "lineup";
};

type MatchSocketMessage = {
  add_date: string | null; // 1970, wenn gelöscht, in zukunft NULL
  remove_date: string | null; // lei gesetzt wenns datum geändert oder gelöscht wert
  match_id: IDType;
  type: "match";
  match_type: MatchType;
};
type TournamentMessage = {
  tournament_id: IDType;
  type: "tournament";
};
type TournamentTeamMessage = {
  tournament_id: IDType;
  type: "tournament_team";
};
type TournamentStageMessage = {
  tournament_id: IDType;
  type: "tournament_stage";
};
type TournamentGroupMessage = {
  tournament_id: IDType;
  type: "tournament_group";
};

type LoginViaSmsMessage = {
  auth_token: string;
  refresh_token: string;
  type: "login";
};
type TaskMessage = {
  task_id: string;
  progress: number;
  total: number;
  finished: boolean;
  type: "task_update";
};
export declare type TaskHandler = (
  progress: number,
  total: number,
  finished: boolean
) => void;

type SocketMessage =
  | TaskMessage
  | LoginViaSmsMessage
  | LineupSocketMessage
  | MatchSocketMessage
  | LazyGoalMessage
  | TournamentMessage
  | TournamentTeamMessage
  | TournamentStageMessage
  | TournamentGroupMessage
  | { type: "ping" };

class EventsSocket {
  private static websocket: Socket | undefined;

  private static userID: IDType = -1;

  private static aoiID: IDType = -1;

  private static endpoint = `wss://${PROD_SOCKET_ENDPOINT}/`;

  private static taskHandler:
    | { handler: TaskHandler; task: string }
    | undefined;

  static init(endpoint: string | undefined | null) {
    if (!endpoint) endpoint = PROD_SOCKET_ENDPOINT;
    if (endpoint === BETA_ENDPOINT) endpoint = BETA_SOCKET_ENDPOINT;
    else if (endpoint === DEV_ENDPOINT) endpoint = DEV_SOCKET_ENDPOINT;
    else if (endpoint === STAG_ENDPOINT) endpoint = STAG_SOCKET_ENDPOINT;
    else endpoint = PROD_SOCKET_ENDPOINT;
    EventsSocket.endpoint = `wss://${endpoint}/`;
    EventsSocket.open();
  }

  public static isOpen(websocket: Socket | undefined): websocket is Socket {
    return !!websocket && websocket.active;
  }

  private static handleLoginMessage(
    // eslint-disable-next-line camelcase
    access_token: string,
    // eslint-disable-next-line camelcase
    refresh_token: string
  ) {
    StaticClient.setToken({
      // eslint-disable-next-line camelcase
      refresh_token,
      // eslint-disable-next-line camelcase
      access_token,
    });
    setTimeout(() => fetchMe(store.dispatch), 100);
  }

  static setUser(id?: IDType, aoi?: IDType) {
    if (id && aoi) {
      EventsSocket.userID = id;
      EventsSocket.aoiID = aoi;
      const sock = EventsSocket.websocket;
      if (sock) {
        sock.emit("user_id", id);
        addLog("socket", { User: id });
      }
      setTimeout(() => {
        if (sock) {
          sock.emit("room", `aoi_${aoi}`);
          addLog("socket", { Room: `aoi_${aoi}` });
        }
      }, 1000);
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        console.log(`Identify as ${id} in room aoi_${aoi}`);
      else
        Sentry.addBreadcrumb({
          type: "info",
          category: "socket",
          data: { user_id: id, room: `aoi_${aoi}` },
        });
    } else if (EventsSocket.userID && EventsSocket.aoiID) {
      const sock = EventsSocket.websocket;
      if (sock) {
        sock.emit("user_id", EventsSocket.userID);
        addLog("socket", { User: id });
      }
      setTimeout(() => {
        if (sock) {
          sock.emit("room", `aoi_${EventsSocket.aoiID}`);
          addLog("socket", { Room: `aoi_${aoi}` });
        }
      }, 1000);
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        console.log(
          `Identify as ${EventsSocket.userID} in room aoi_${EventsSocket.aoiID}`
        );
      else
        Sentry.addBreadcrumb({
          type: "info",
          category: "socket",
          data: {
            user_id: EventsSocket.userID,
            room: `aoi_${EventsSocket.aoiID}`,
          },
        });
    }
  }

  static open() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("\x1b[35m%s%O\x1b[0m", `Open WS to ${EventsSocket.endpoint}`);
    addLog("socket", { Open: EventsSocket.endpoint });
    EventsSocket.close();
    EventsSocket.websocket = io(EventsSocket.endpoint);
    EventsSocket.websocket.on("message", EventsSocket.onMessage);
    EventsSocket.websocket.on("connect", EventsSocket.onOpen);
    EventsSocket.websocket.on("connect_error", EventsSocket.onError);
    EventsSocket.websocket.on("disconnect", EventsSocket.onClose);
  }

  static close() {
    if (EventsSocket.isOpen(EventsSocket.websocket))
      EventsSocket.websocket.close();
  }

  static setEndpoint(endpoint: string) {
    const newEndpoint =
      endpoint === DEV_ENDPOINT
        ? DEV_SOCKET_ENDPOINT
        : endpoint === BETA_ENDPOINT
        ? BETA_SOCKET_ENDPOINT
        : endpoint === STAG_ENDPOINT
        ? STAG_SOCKET_ENDPOINT
        : PROD_SOCKET_ENDPOINT;
    if (`wss://${newEndpoint}/` !== EventsSocket.endpoint) {
      EventsSocket.endpoint = `wss://${newEndpoint}/`;
      EventsSocket.close();
      EventsSocket.open();
    }
  }

  /**
   *
   * @private
   * @param reason
   * @param description
   */
  private static onClose(
    reason: Socket.DisconnectReason,
    description?: DisconnectDescription
  ) {
    console.log("\x1b[35m%s%O\x1b[0m", "WS closed, reason: ", reason);
    addLog("socket", { Closed: { reason, description } });
  }

  private static handleTaskMessage(message: TaskMessage) {
    if (this.taskHandler && this.taskHandler.task === message.task_id)
      this.taskHandler.handler(
        message.progress,
        message.total,
        message.finished
      );
    if (message.finished) {
      this.taskHandler = undefined;
    }
  }

  private static handleMatchMessage(
    addDate: string | null,
    removeDate: string | null,
    matchId: IDType,
    matchType: MatchType
  ) {
    if (addDate) {
      if (matchType === MatchType.LEAGUE) {
        client()
          .getMatch(matchId)
          .then((match) => {
            store.dispatch({
              type: MatchActionTypes.REFRESH_MATCH_DAYS,
              payload: {
                match,
                addDate,
                removeDate,
                aoiID: EventsSocket.aoiID,
              },
            });
          }, errorLogging);
      } else {
        client()
          .getCupMatch(matchId, matchType === MatchType.GROUP)
          .then((match) => {
            if (
              store
                .getState()
                .cup.standings.find((v) => v.id === match.tournament.id)
            ) {
              fetchStandings(store.dispatch, match.tournament.id);
            }
            store.dispatch({
              type: CupActionTypes.REFRESH_MATCH_SUCCESS,
              payload: match,
            });
          }, errorLogging);
      }
    } else if (removeDate) {
      store.dispatch({
        type: MatchActionTypes.REMOVE_MATCH,
        payload: {
          id: matchId,
          date: new Date(removeDate),
        },
      });
    }
  }

  private static handleLineupMessage(matchId: IDType, teamId: IDType) {
    const match = store.getState().match.matches.find((m) => m.id === matchId);
    if (match)
      getLineupOfMatch(
        store.dispatch,
        matchId,
        teamId === match.team1.id
          ? MatchLocationEnum.HOME
          : MatchLocationEnum.AWAY
      );
  }

  private static handleLazyGoalMessage(leagueId: IDType) {
    const state = store.getState();
    if (state.standing.value.find((v) => v.id === leagueId)) {
      fetchByLeague(store.dispatch, leagueId);
    }
  }

  private static handleTournamentMessage(id: IDType) {
    client()
      .getCup(id)
      .then((cup) => {
        store.dispatch({
          type: CupActionTypes.FETCH_CUP_SUCCESS,
          payload: cup,
        });
      }, errorLogging);
  }

  private static handleTournamentGroupMessage(id: IDType) {
    client()
      .getCupGroups(id)
      .then((gps) => {
        store.dispatch({
          type: CupActionTypes.FETCH_GROUPS_SUCCESS,
          payload: { id, fetchDate: new Date(), response: gps },
        });
      }, errorLogging);
  }

  private static handleTournamentStageMessage(id: IDType) {
    client()
      .getCupStages(id)
      .then((gps) => {
        store.dispatch({
          type: CupActionTypes.FETCH_STAGES_SUCCESS,
          payload: { id, fetchDate: new Date(), response: gps },
        });
      }, errorLogging);
  }

  private static onMessage(payload: any) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("\x1b[35m%s%O\x1b[0m", `WS Message: `, payload);
    addLog("socket", { Message: payload });
    try {
      const message: SocketMessage = JSON.parse(payload as string);
      if (message.type === "task_update") {
        EventsSocket.handleTaskMessage(message);
      }
      if (message.type === "match") {
        EventsSocket.handleMatchMessage(
          message.add_date,
          message.remove_date,
          message.match_id,
          message.match_type
        );
      }
      if (message.type === "lazy_goal") {
        EventsSocket.handleLazyGoalMessage(message.league_id);
      }
      if (message.type === "lineup") {
        EventsSocket.handleLineupMessage(message.match_id, message.team_id);
      }

      if (message.type === "login") {
        EventsSocket.handleLoginMessage(
          message.auth_token,
          message.refresh_token
        );
      }
      if (message.type === "tournament" || message.type === "tournament_team") {
        EventsSocket.handleTournamentMessage(message.tournament_id);
      }
      if (message.type === "tournament_group") {
        EventsSocket.handleTournamentGroupMessage(message.tournament_id);
      }
      if (message.type === "tournament_stage") {
        EventsSocket.handleTournamentStageMessage(message.tournament_id);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  private static onOpen() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("\x1b[35m%s%O\x1b[0m", `WS open to ${EventsSocket.endpoint}`);
    addLog("socket", { Connected: EventsSocket.endpoint });
    setTimeout(EventsSocket.setUser, 1000);
  }

  private static onError(error: Error) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("\x1b[35m%s%O\x1b[0m", "WS Error:", error);
    addLog("socket", { Error: error });
  }

  public static onTaskStarted(task: Task, handler: TaskHandler) {
    const sock = EventsSocket.websocket;
    EventsSocket.taskHandler = { task: task.id, handler };
    if (sock) {
      sock.emit("room", task.room);
      addLog("socket", { Room: task.room, Task: task });
    }
  }
}

export default EventsSocket;
