/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum GoalTypeEnum {
    LONG_SHOT = 'LONG_SHOT',
    EASY_GOAL = 'EASY_GOAL',
    HEADER = 'HEADER',
    OWN_GOAL = 'OWN_GOAL',
    FREE_KICK = 'FREE_KICK',
    PENALTY = 'PENALTY',
    POWER_PLAY = 'POWER_PLAY',
    SHORT_HANDER = 'SHORT_HANDER',
    PENALTY_SHOT = 'PENALTY_SHOT',
    EMPTY_NET = 'EMPTY_NET',
    DISTANCE = 'DISTANCE',
    REBOUND = 'REBOUND',
}
