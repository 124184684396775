/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { League } from '../models/League';
import type { LeagueCreate } from '../models/LeagueCreate';
import type { LeagueList } from '../models/LeagueList';
import type { LeagueListElement } from '../models/LeagueListElement';
import type { LeagueMatchList } from '../models/LeagueMatchList';
import type { LeaguePatch } from '../models/LeaguePatch';
import type { LeagueTemplate } from '../models/LeagueTemplate';
import type { LeagueTemplateList } from '../models/LeagueTemplateList';
import { SportEnum } from '../models/SportEnum';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LeagueService {
    /**
     * Get League By Id
     * @param leagueId
     * @returns League Successful Response
     * @throws ApiError
     */
    public static getLeagueByIdLeagueLeagueIdGet(
        leagueId: number,
    ): CancelablePromise<League> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/league/{league_id}',
            path: {
                'league_id': leagueId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch League
     * @param leagueId
     * @param requestBody
     * @returns League Successful Response
     * @throws ApiError
     */
    public static patchLeagueLeagueLeagueIdPatch(
        leagueId: number,
        requestBody: LeaguePatch,
    ): CancelablePromise<League> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/league/{league_id}',
            path: {
                'league_id': leagueId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete League
     * @param leagueId
     * @returns number Successful Response
     * @throws ApiError
     */
    public static deleteLeagueLeagueLeagueIdDelete(
        leagueId: number,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/league/{league_id}',
            path: {
                'league_id': leagueId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Leagues
     * @param areaOfInterestId
     * @param sports
     * @param activeOnly
     * @returns LeagueList Successful Response
     * @throws ApiError
     */
    public static getLeaguesLeagueGet(
        areaOfInterestId: number,
        sports: SportEnum = SportEnum.SOCCER,
        activeOnly: boolean = true,
    ): CancelablePromise<LeagueList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/league/',
            query: {
                'area_of_interest_id': areaOfInterestId,
                'sports': sports,
                'active_only': activeOnly,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create League
     * @param requestBody
     * @returns League Successful Response
     * @throws ApiError
     */
    public static createLeagueLeaguePost(
        requestBody: LeagueCreate,
    ): CancelablePromise<League> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/league/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Team To League
     * @param leagueId
     * @param teamId
     * @returns League Successful Response
     * @throws ApiError
     */
    public static addTeamToLeagueLeagueAddTeamPost(
        leagueId: number,
        teamId: number,
    ): CancelablePromise<League> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/league/add_team',
            query: {
                'league_id': leagueId,
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Team From League
     * @param leagueId
     * @param teamId
     * @returns League Successful Response
     * @throws ApiError
     */
    public static removeTeamFromLeagueLeagueRemoveTeamPost(
        leagueId: number,
        teamId: number,
    ): CancelablePromise<League> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/league/remove_team',
            query: {
                'league_id': leagueId,
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Multiple Leagues
     * @param requestBody
     * @returns League Successful Response
     * @throws ApiError
     */
    public static createMultipleLeaguesLeagueMultiplePost(
        requestBody: Array<LeagueCreate>,
    ): CancelablePromise<Array<League>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/league/multiple',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set League Order
     * @param areaOfInterestId
     * @param requestBody
     * @returns LeagueListElement Successful Response
     * @throws ApiError
     */
    public static setLeagueOrderLeagueSetOrderPost(
        areaOfInterestId: number,
        requestBody: Array<number>,
    ): CancelablePromise<Array<LeagueListElement>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/league/set_order',
            query: {
                'area_of_interest_id': areaOfInterestId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Deactivate League
     * @param leagueId
     * @returns League Successful Response
     * @throws ApiError
     */
    public static deactivateLeagueLeagueDeactivateLeagueIdPut(
        leagueId: number,
    ): CancelablePromise<League> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/league/deactivate/{league_id}',
            path: {
                'league_id': leagueId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Duplicate League
     * @param leagueId
     * @param year
     * @param deactivateOld
     * @returns League Successful Response
     * @throws ApiError
     */
    public static duplicateLeagueLeagueDuplicateLeagueLeagueIdYearYearPost(
        leagueId: number,
        year: number,
        deactivateOld: boolean = true,
    ): CancelablePromise<League> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/league/duplicate_league/{league_id}/year/{year}',
            path: {
                'league_id': leagueId,
                'year': year,
            },
            query: {
                'deactivate_old': deactivateOld,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Import Playoff Hockey
     * @param divisionId
     * @param lastDay
     * @param name
     * @returns any Successful Response
     * @throws ApiError
     */
    public static importPlayoffHockeyLeagueImportPlayoffHockeyPost(
        divisionId: number,
        lastDay: string,
        name?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/league/import_playoff_hockey',
            query: {
                'division_id': divisionId,
                'last_day': lastDay,
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Get League Match By Date
     * @param date
     * @param areaOfInterest
     * @param sports
     * @returns LeagueMatchList Successful Response
     * @throws ApiError
     */
    public static getLeagueMatchByDateLeagueMatchesDateGet(
        date: string,
        areaOfInterest: number,
        sports: SportEnum,
    ): CancelablePromise<LeagueMatchList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/league_matches/date',
            query: {
                'date': date,
                'area_of_interest': areaOfInterest,
                'sports': sports,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get League Template
     * @param name
     * @returns LeagueTemplate Successful Response
     * @throws ApiError
     */
    public static getLeagueTemplateLeagueTemplateNameGet(
        name: string,
    ): CancelablePromise<LeagueTemplate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/league_template/{name}',
            path: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update League Template
     * @param name
     * @param requestBody
     * @returns LeagueTemplate Successful Response
     * @throws ApiError
     */
    public static updateLeagueTemplateLeagueTemplateNamePut(
        name: string,
        requestBody: LeagueTemplate,
    ): CancelablePromise<LeagueTemplate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/league_template/{name}',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete League Template
     * @param name
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteLeagueTemplateLeagueTemplateNameDelete(
        name: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/league_template/{name}',
            path: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get League Templates
     * @returns LeagueTemplateList Successful Response
     * @throws ApiError
     */
    public static getLeagueTemplatesLeagueTemplateGet(): CancelablePromise<LeagueTemplateList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/league_template/',
        });
    }
    /**
     * Create League Template
     * @param name
     * @param requestBody
     * @returns LeagueTemplate Successful Response
     * @throws ApiError
     */
    public static createLeagueTemplateLeagueTemplatePost(
        name: string,
        requestBody: LeagueTemplate,
    ): CancelablePromise<LeagueTemplate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/league_template/',
            query: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
