import React, { useContext, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faTrophy } from "@fortawesome/pro-regular-svg-icons";
import { formatDate, isAdmin } from "../../../../config/utils";
import { showTranslated } from "../../../../config/translator";
import { ClavaContext } from "../../../../config/contexts";
import { parseParams } from "../../../../config/routes";
import { TournamentMinimal } from "../../../../client/api";

const CupSmall: React.FC<{ cup: TournamentMinimal }> = ({ cup }) => {
  const { user, l } = useContext(ClavaContext);
  const params = useParams();
  const { tournamentId } = params;

  const realCupId = useMemo(() => {
    if (!tournamentId) return -1;
    const id = parseInt(tournamentId, 10);
    return Number.isNaN(id) ? -1 : id;
  }, [tournamentId]);
  const status = useMemo(() => {
    const now = new Date().getTime();
    const start = new Date(cup.dateFrom).getTime();
    const end = new Date(cup.dateTo).getTime();
    if (now > end) return "past";
    if (now < start) return "future";
    return "live";
  }, [cup.dateFrom, cup.dateTo]);
  if (realCupId === cup.id) {
    return null;
  }
  return (
    <NavLink
      to={parseParams({ tournamentId: cup.id }, params)}
      className="flex flex-row items-stretch justify-start my-2 mx-4"
    >
      <div
        className={`min-w-20 max-w-20 flex flex-col bg-bg dark:bg-bg-dark items-center justify-center rounded-l-xl border border-light-gray dark:border-light-gray-dark ${
          status !== "past" ? "!bg-primary-20 !border-primary" : ""
        }`}
      >
        <FontAwesomeIcon icon={faTrophy} className="w-6 h-6" />
      </div>
      <div className="bg-bg dark:bg-bg-dark py-2 px-4 flex-1 border-t border-b border-t-light-gray border-b-light-gray dark:border-t-light-gray-dark dark:border-b-light-gray-dark flex flex-col items-stretch justify-between">
        <span className="font-bold">{showTranslated(cup.name, l)}</span>
        <span>
          {`${formatDate(cup.dateFrom, l, false, false, true)} - ${formatDate(
            cup.dateTo,
            l,
            false,
            false,
            true
          )}`}
        </span>
      </div>
      <button
        type="button"
        className="bg-bg dark:bg-bg-dark min-w-20 max-w-20 outline-0 flex flex-col items-center justify-center rounded-r-xl border border-light-gray dark:border-light-gray-dark "
      >
        {isAdmin(user) && <span className="text-muted">{`[${cup.id}]`}</span>}
        <FontAwesomeIcon icon={faChevronRight} className="w-6 h-6" />
      </button>
    </NavLink>
  );
};

export default CupSmall;
// reload
